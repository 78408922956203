<template>
	<div style="display: flex; flex-direction: row; margin: 20px auto;">

		<section class="email-section">
			<header>
				<h1 style="color: bisque; margin: 10px auto; margin-bottom: 20px;">Email verificate</h1>
			</header>
			<v-card v-for="email in emails" :key="email" elevation="17">
				<v-card-title style="width: fit-content; margin: auto;">{{ email }}</v-card-title>
			</v-card>
		</section>
		<section class="setting-section">
			<div class="btn-col">
				<label>
					Esporta CSV
				</label>
				<div class="bottone" v-on:click="exporta">Esporta</div>
			</div>
		</section>
	</div>
</template>


<script>
import store from '@/store/index.js';
import { getEmails, exportCSV } from '../functions.js';


export default {
	name: 'EmailsView',
	data: () => ({

	}),
	computed: {
		emails() {
			return store.getters.emails;
		}
	},
	methods: {
		exporta() {
			exportCSV();
		}
	},
	async mounted() {
		getEmails();
	}
}
</script>
<style>
.email-section {
	position: relative;
	display: flex;
	flex-direction: column;
	width: fit-content;
	margin-left: 30px;
}

.email-section .v-card {
	position: relative;
	width: fit-content;
	margin: 3px auto;
	min-width: 420px;
	border-radius: 12px;
	border: 2px solid rgb(227, 139, 24);
	background-color: bisque;
	transition: 0.2s ease-in-out;
}

.email-section .v-card:hover {
	scale: 1.02;
}

.setting-section {
	position: relative;
	display: flex;
	flex-direction: row;
	margin: 10px auto;
	width: 60%;
	height: fit-content;
}

.bottone {
	position: relative;
	width: fit-content;
	padding: 5px 10px;
	border: 2px solid black;
	border-radius: 5px;
	background-color: bisque;
}
.btn-col {
	display: flex;
	flex-direction: column;
	width: fit-content;
	align-items: center;
	padding: 10px;
	border: 1px solid bisque;
	border-radius: 7px;
}
.btn-col label {
	color: bisque;
	font-size: 18px;
	margin: auto;
	margin-bottom: 10px;
}

</style>