import axios from 'axios';
import { deleteFromReward } from '@/model_functions';

const rewardModule = {
  state: {
    rewards: [],
    filter_rewards: [],
    reward: {
      "id": 0,
      "active": 0,
      "type": 0,
      "type_config": 0,
      "expire_at": '2024-03-25 00:00:00',
      "pack_id": 'nome_pacchetto,nome_pacchetto',
      "birds": 'id1,8|id2,3',
      "coins": 0,
      "duel_coins": 0,
      "special_coins": 0,
      "feathers": 0,
      "vip_points": 0,
      "better_photo": 0,
      "rapid_focus": 0,
      "probability": 0,
      "bird_radar": 0,
      "luck": 0,
      "perfect_exposure": 0,
      "double_photo": 0,
      "avatar": 0,
      "border": 0,
      "card": 0,
      "emoji": 0
    },
    updateReward: {},
    chosenRewardType: '',
    updateChosenRewardType: '',
    rewardTypes: [
      {
        name: "Fisso",
        icon: "mdi-lock"
      },
      {
        name: "A livello",
        icon: "mdi-account-arrow-up",
      },
      {
        name: "Ad evento",
        icon: "mdi-calendar",
      },
      {
        name: "Speciale",
        icon: "mdi-star",
      },
      {
        name: "Campionato",
        icon: "mdi-trophy",
      },
      {
        name: "Giornaliero",
        icon: "mdi-sun-clock",
      },
      {
        name: "Duello",
        icon: "mdi-fencing",
      },
      {
        name: "Bentornato",
        icon: "mdi-human-greeting"
      },
      {
        name: "A tempo",
        icon: "mdi-timer-sand"
      },
    ],
    rewardTypesIndex: {
      "Fisso": 0,
      "A livello": 1,
      "Ad evento": 2,
      "Speciale": 3,
      "Campionato": 4,
      "Giornaliero": 5,
      "Duello": 6,
      "Bentornato": 7,
      "A tempo": 8
    },
    reward_filter: {
      id_reward_filter: '',
      pack_id_reward_filter: '',
      type_reward_filter: 9999,
    },
  },
  getters: {
    rewards(state) {
      return state.rewards;
    },
    filter_rewards(state) {
      return state.filter_rewards;
    },
    reward(state) {
      return state.reward;
    },
    updateReward(state) {
      return state.updateReward;
    },
    chosenRewardType(state) {
      return state.chosenRewardType;
    },
    updateChosenRewardType(state) {
      return state.updateChosenRewardType;
    },
    rewardTypes(state) {
      return state.rewardTypes;
    },
    rewardTypesIndex(state) {
      return state.rewardTypesIndex;
    },
    getRewardIDfilter(state) {
      return state.reward_filter.id_reward_filter;
    },
    getRewardPackfilter(state) {
      return state.reward_filter.pack_id_reward_filter;
    },
    getRewardTypefilter(state) {
      return state.reward_filter.type_reward_filter;
    },
  },
  mutations: {
    setRewards(state, payload) {
      state.rewards = payload;
      state.filter_rewards = state.rewards.filter(reward => {
        const idReward = reward.id.toString().includes(state.reward_filter.id_reward_filter ?? '');
        const packIdReward = (reward.pack_id ?? '').includes(state.reward_filter.pack_id_reward_filter ?? '');

        let typeReward;
        if (state.reward_filter.type_reward_filter === 9999) {
          typeReward = true;
        } else {
          typeReward = reward.type === state.reward_filter.type_reward_filter;
        }
        return idReward && packIdReward && typeReward;
      });
    },
    setFilterRewards(state, payload) {
      state.filter_rewards = payload;
    },
    setReward(state, payload) {
      state.reward = payload;
    },
    setUpdateReward(state, payload) {
      state.updateReward = payload;
      var isNull = false;
      if (payload.type == undefined)
        isNull = true;
      state.updateChosenRewardType = isNull ? '' : state.rewardTypes[payload.type].name;
    },
    setChosenRewardType(state, payload) {
      state.chosenRewardType = payload;
    },
    setUpdateChosenRewardType(state, payload) {
      state.updateChosenRewardType = payload;
    },
    rewardFilterList(state) {
      state.filter_rewards = state.rewards.filter(reward => {
        const idReward = reward.id.toString().includes(state.reward_filter.id_reward_filter ?? '');
        const packIdReward = (reward.pack_id ?? '').includes(state.reward_filter.pack_id_reward_filter ?? '');

        let typeReward;
        if (state.reward_filter.type_reward_filter === 9999) {
          typeReward = true;
        } else {
          typeReward = reward.type === state.reward_filter.type_reward_filter;
        }

        return idReward && packIdReward && typeReward;
      });
    },
    setRewardFilter(state, { filterName, value }) {
      switch (filterName) {
        case 0:
          state.reward_filter.id_reward_filter = value;
          break;
        case 1:
          state.reward_filter.pack_id_reward_filter = value;
          break;
        case 2:
          state.reward_filter.type_reward_filter = value;
          break;

        default:
          break;
      }
    },
  },
  actions: {
    setRewards({ commit }, payload) {
      commit('setRewards', payload);
    },
    setFilterRewards({ commit }, payload) {
      commit('setFilterRewards', payload);
    },
    setReward({ commit }, payload) {
      commit('setReward', payload);
    },
    rewardFilterList({ commit }) {
      commit('rewardFilterList');
    },
    setRewardIdfilter({ commit }, payload) {
      commit('setRewardFilter', { filterName: 0, value: payload });
    },
    setRewardPackfilter({ commit }, payload) {
      commit('setRewardFilter', { filterName: 1, value: payload });
    },
    setRewardTypefilter({ commit }, payload) {
      commit('setRewardFilter', { filterName: 2, value: payload });
    },
    setUpdateReward({ commit }, payload) {
      commit('setUpdateReward', payload);
    },
    setChosenRewardType({ commit }, payload) {
      commit('setChosenRewardType', payload);
    },
    setUpdateChosenRewardType({ commit }, payload) {
      commit('setUpdateChosenRewardType', payload);
    },
    async getRewardRequest({ commit }) {
      axios
        .get('api/rewards/get')
        .then((res) => {
          if (res.data.status) {
            commit("setRewards", res.data.data);
          } else {
            commit("logOut");
            commit('setLogged', false);
          }
        })
        .catch(() => {
          commit("logOut");
          commit('setLogged', false);
        });
    },
    async updateRewardRequest({ commit, state }, callback) {
      const q = state.updateReward;
      q.type = state.rewardTypesIndex[state.updateChosenRewardType];
      await axios
        .put('api/rewards/update/' + q.id, q)
        .then((res) => {
          if (res.data.status) {
            commit("setRewards", res.data.data);
            commit('setResponse', true);
            commit('setSnackbarText', "Modificata missione ID: " + q.id);
            commit('setSnackbar', true);
          } else {
            commit('setResponse', false);
            commit('setSnackbarText', res.data.data);
            commit('setSnackbar', true);
          }
          callback();
        }).catch((error) => {
          commit('setResponse', false);
          commit('setSnackbarText', error.response.data.data ?? error);
          commit('setSnackbar', true);
          callback();
        });
    },
    async duplicateRewardRequest({ commit, state }, callback) {
      const q = state.updateReward;
      q.type = state.rewardTypesIndex[state.updateChosenRewardType];

      await axios
        .post('api/rewards/create/', q)
        .then((res) => {
          if (res.data.status) {
            commit("setRewards", res.data.data);
            commit('setResponse', true);
            commit('setSnackbarText', "Creata nuova missione");
            commit('setSnackbar', true);
          } else {
            commit('setResponse', false);
            commit('setSnackbarText', res.data.data);
            commit('setSnackbar', true);
          }
          callback();
        }).catch((error) => {
          commit('setResponse', false);
          commit('setSnackbarText', error.response.data.data ?? error);
          commit('setSnackbar', true);
          callback();
        });
    },
    async createRewardRequest({ commit, state }, callback) {
      const q = state.reward;
      q.type = state.rewardTypesIndex[state.chosenRewardType];
      await axios
        .post('api/rewards/create/', q)
        .then((res) => {
          if (res.data.status) {
            commit("setRewards", res.data.data);
            commit('setResponse', true);
            commit('setSnackbarText', "Creata nuova missione");
            commit('setSnackbar', true);
            commit('setReward', {
              "id": 0,
              "active": 0,
              "type": 0,
              "type_config": 0,
              "expire_at": '2024-03-25 00:00:00',
              "pack_id": 'nome_pacchetto,nome_pacchetto',
              "birds": 'id1,8|id2,3',
              "coins": 0,
              "duel_coins": 0,
              "special_coins": 0,
              "feathers": 0,
              "vip_points": 0,
              "better_photo": 0,
              "rapid_focus": 0,
              "probability": 0,
              "bird_radar": 0,
              "luck": 0,
              "perfect_exposure": 0,
              "double_photo": 0,
              "avatar": 0,
              "border": 0,
              "card": 0,
              "emoji": 0
            });
          } else {
            commit('setResponse', false);
            commit('setSnackbarText', res.data.data);
            commit('setSnackbar', true);
          }
          callback();
        }).catch((error) => {
          commit('setResponse', false);
          commit('setSnackbarText', error.response.data.data ?? error);
          commit('setSnackbar', true);
          callback();
        });
    },
    async deleteRewardRequest({ commit, state }, callback) {
      const q = state.updateReward;
      await axios
        .delete('api/rewards/delete/' + q.id)
        .then(() => {
          deleteFromReward(q.id);
          commit('setResponse', true);
          commit('setSnackbarText', "Eliminate missione ID: " + q.id);
          commit('setSnackbar', true);
          callback();
        }).catch((error) => {
          commit('setResponse', false);
          commit('setSnackbarText', error.response.data.data ?? error);
          commit('setSnackbar', true);
          callback();
        });
    }
  },
};

export default rewardModule;
