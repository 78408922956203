<template>
    <v-card class="quest-card"
        :style="{ backgroundColor: currentQuest.active ? '#c402a1' : '#f77070', borderColor: '#222222' }">
        <v-card-text class="flex row">
            <div class="flex column gap30">
                <div class="flex column">
                    <strong>Range: </strong>
                    <v-select :value="chosenQuestRange" solo :items="rangesName" v-on:change="setRange">
                    </v-select>
                    <v-icon class="special-icon" large>
                        {{ rangesIcons[chosenQuestRange] }}
                    </v-icon>
                    <div v-if="chosenQuestRange === 'Uccello' || chosenQuestRange === 'Scenario'" class="flex row">
                        <strong>ID range:</strong> <v-spacer></v-spacer> <input v-model="currentQuest.range_id" />
                    </div>
                </div>
                <div class="flex column">
                    <strong>Rarita: </strong>
                    <v-select :value="chosenQuestRarity" solo :items="rarityName" v-on:change="setRarity">
                    </v-select>
                </div>
                <div class="flex column">
                    <input type="checkbox" class="checkbox" v-model="currentQuest.active" />
                    <strong style="margin: auto;">{{ currentQuest.active ? 'Attivo' : 'Disattivo' }}</strong>
                </div>
            </div>
            <div class="flex column">
                <div class="flex row">
                    <strong>Classe:</strong> <v-spacer></v-spacer>
                    <v-select solo :items="questClass" :label="chosenQuestClass" v-on:change="setClass">
                        <template v-slot:item="{ item }">
                            {{ item.name }}
                        </template>
                        <template v-slot:selection="{ item }">
                            {{ item.name }}
                        </template>
                    </v-select>
                </div>
                <div class="flex row">
                    <strong>Tipo:</strong>
                    <v-spacer></v-spacer>
                    <v-select solo :items="questTypes"
                        :label="chosenQuestType.replace('X', currentQuest.number_to_get > 0 ? currentQuest.number_to_get : 'X')"
                        v-on:change="setType">
                        <template v-slot:item="{ item }">
                            {{ item.name }}
                        </template>
                        <template v-slot:selection="{ item }">
                            {{ item.name.replace('X', currentQuest.number_to_get > 0 ? currentQuest.number_to_get : 'X') }}
                        </template>
                    </v-select>
                </div>
                <div class="flex row">
                    <strong>Numero:</strong> <v-spacer></v-spacer> <input v-model="currentQuest.number_to_get" />
                </div>
                <div class="flex row">
                    <strong>Punti:</strong> <v-spacer></v-spacer> <input v-model="currentQuest.points_to_get" />
                </div>
                <div class="flex row">
                    <strong>Ripetizione missione:</strong> <v-spacer></v-spacer> <input v-model="currentQuest.available_times" />
                </div>
                <div class="flex row">
                    <strong>Categoria: </strong>
                    <v-select :value="chosenQuestCategory" solo :items="categories" v-on:change="setCategory">
                    </v-select>
                </div>
            </div>
            <div class="flex column">
                <div class="flex row">
                    <strong>ID missione:</strong> {{ currentQuest.id }}
                </div>
                <!-- <div class="flex row">
                    <strong>ID categoria:</strong> <v-spacer></v-spacer> <input v-model="currentQuest.category_id" />
                </div> -->
                <div class="flex row">
                    <strong>ID evento:</strong> <v-spacer></v-spacer> <input v-model="currentQuest.event_id" />
                </div>
            </div>
        </v-card-text>
    </v-card>
</template>
<script>

import store from '@/store/index.js'
export default {
    name: "QuestCard",
    data() {
        return {
        }
    },
    /* watch: {
        currentQuest: {
            handler() {
                if (this.new) {
                    store.dispatch('setChosenQuestRange', this.rangesName[this.currentQuest.range]);
                    store.dispatch('setChosenQuestCategory', this.typesName[this.currentQuest.category]);
                    store.dispatch('setChosenQuestRarity', this.rarityFilter[this.currentQuest.rarity]);
                    store.dispatch('setChosenQuestClass', this.currentQuest.quest_class);
                    store.dispatch('setChosenQuestType', this.currentQuest.quest_type);
                } else {
                    store.dispatch('setUpdateChosenQuestRange', this.rangesName[this.currentQuest.range]);
                    store.dispatch('setUpdateChosenQuestCategory', this.typesName[this.currentQuest.category]);
                    store.dispatch('setUpdateChosenQuestRarity', this.rarityFilter[this.currentQuest.rarity]);
                    store.dispatch('setUpdateChosenQuestClass', this.currentQuest.quest_class);
                    store.dispatch('setUpdateChosenQuestType', this.currentQuest.quest_type);
                }
            }
        }
    }, */
    props: {
        new: Boolean
    },
    methods: {
        setType(value) {
            if (this.new)
                store.dispatch('setChosenQuestType', value.type);
            else
                store.dispatch('setUpdateChosenQuestType', value.type);
        },
        setClass(value) {
            if (this.new)
                store.dispatch('setChosenQuestClass', value.class);
            else
                store.dispatch('setUpdateChosenQuestClass', value.class);
        },
        setRange(value) {
            if (this.new)
                store.dispatch('setChosenQuestRange', value);
            else
                store.dispatch('setUpdateChosenQuestRange', value);
        },
        setRarity(value) {
            if (this.new)
                store.dispatch('setChosenQuestRarity', value);
            else
                store.dispatch('setUpdateChosenQuestRarity', value);
        },
        setCategory(value) {
            if (this.new)
                store.dispatch('setChosenQuestCategory', value);
            else
                store.dispatch('setUpdateChosenQuestCategory', value);
        },
        formatType(value) {
            if (this.currentQuest.number_to_get !== null)
                return value.replace('X', this.currentQuest.number_to_get)
            else
                return value
        }
    },
    computed: {
        currentQuest() {
            return this.new ? store.getters.quest : store.getters.updateQuest;
        },
        chosenQuestRange() {
            return this.new ? store.getters.chosenQuestRange : store.getters.updateChosenQuestRange;
        },
        chosenQuestCategory() {
            return this.new ? store.getters.chosenQuestCategory : store.getters.updateChosenQuestCategory;
        },
        chosenQuestRarity() {
            return this.new ? store.getters.chosenQuestRarity : store.getters.updateChosenQuestRarity;
        },
        chosenQuestClass() {
            return this.new ? store.getters.chosenQuestClass : store.getters.updateChosenQuestClass;
        },
        chosenQuestType() {
            return this.new ? store.getters.chosenQuestType : store.getters.updateChosenQuestType;
        },
        rangesName() {
            return store.getters.questRangesName;
        },
        typesName() {
            return store.getters.questTypesName;
        },
        rarityFilter() {
            return store.getters.questRarityFilter;
        },
        rangesIcons() {
            return store.getters.questRangesIcons;
        },
        rarityName() {
            return store.getters.questRarityName;
        },
        questClass() {
            return store.getters.questClass;
        },
        questTypes() {
            return this.new ? store.getters.questTypes : store.getters.updateQuestTypes;
        },
        questCategoryTypes() {
            return store.getters.questCategoryTypes;
        },
        categories() {
            var types = [];
            for (let index = store.getters.questQuestCategories.length - 1; index >= 0; index--) {
                const element = store.getters.questQuestCategories[index];
                if (element.event_id === parseInt(this.currentQuest.event_id)) {
                    types.push(this.questCategoryTypes[element.type]);
                }
            }
            return types;
        }
    },
}
</script>
<style>
.v-text-field__details {
    display: none;
}
</style>