<template>
    <v-card class="event-card" :style="{ backgroundColor: currentPack.active ? '#b4f36d' : '#f77070' }">
        <v-card-title class="flex row title-new-pack">
            <strong>Nome:</strong><input v-model="currentPack.pack_id" />
        </v-card-title>
        <v-card-text class="flex row align-items">
            <div class="flex column h-margin10 gap30">
                <div class="currency-row">
                    <strong>ID:</strong> <v-spacer></v-spacer> <input v-model="currentPack.id" />
                </div>
                <div>
                    <v-select :value="chosenPackCurrency" solo :items="packCurrency" v-on:change="setCurrency"></v-select>
                    <v-icon class="special-icon" large>
                        {{ packCurrencyIcons[packCurrencyIndex[chosenPackCurrency]] }}
                    </v-icon>
                </div>
                <input type="checkbox" v-model="currentPack.active" />
            </div>
            <div class="flex column h-margin10 space-around">
                <div class="event-row">
                    <strong>ID scenario:</strong> <v-spacer></v-spacer> <input v-model="currentPack.scenery_id" />
                </div>
                <div class="event-row">
                    <strong>ID rank:</strong> <v-spacer></v-spacer> <input v-model="currentPack.rank_id" />
                </div>
                <div class="event-row">
                    <strong>Uccelli totali:</strong> <v-spacer></v-spacer> <input v-model="currentPack.total_elements" />
                </div>
                <div class="event-row">
                    <strong>Monete minimo:</strong> <v-spacer></v-spacer> <input v-model="currentPack.min_coins" />
                </div>
                <div class="event-row">
                    <strong>Monete massimo:</strong> <v-spacer></v-spacer> <input v-model="currentPack.max_coins" />
                </div>
                <div class="event-row">
                    <strong>Piume minimo:</strong> <v-spacer></v-spacer> <input v-model="currentPack.min_feathers" />
                </div>
                <div class="event-row">
                    <strong>Piume massimo:</strong> <v-spacer></v-spacer> <input v-model="currentPack.max_feathers" />
                </div>
            </div>
            <div class="flex column h-margin10 space-around">
                <div class="event-row">
                    <strong>Comuni:</strong> <v-spacer></v-spacer> <input v-model="currentPack.commons" />
                </div>
                <div class="event-row">
                    <strong>Rare:</strong> <v-spacer></v-spacer> <input v-model="currentPack.rares" />
                </div>
                <div class="event-row">
                    <strong>Epiche:</strong> <v-spacer></v-spacer> <input v-model="currentPack.epics" />
                </div>
                <div class="event-row">
                    <strong>Mitiche:</strong> <v-spacer></v-spacer> <input v-model="currentPack.mythics" />
                </div>
                <div class="event-row">
                    <strong>Leggendarie:</strong> <v-spacer></v-spacer> <input v-model="currentPack.legends" />
                </div>
                <div class="event-row">
                    <strong>Speciali:</strong> <v-spacer></v-spacer> <input v-model="currentPack.specials" />
                </div>
            </div>
        </v-card-text>
    </v-card>
</template>
<script>

import store from '@/store/index.js'
export default {
    name: "PackCard",
    data() {
        return {
        }
    },
    props: {
        new: Boolean
    },
    methods: {
        setCurrency(value) {
            if (this.new)
                store.dispatch('setChosenPackCurrency', value);
            else
                store.dispatch('setUpdateChosenPackCurrency', value);
        },
    },
    computed: {
        currentPack() {
            return this.new ? store.getters.pack : store.getters.updatePack;
        },
        chosenPackCurrency() {
            return this.new ? store.getters.chosenPackCurrency : store.getters.updateChosenPackCurrency;
        },
        packCurrency() {
            var types = [];
            store.getters.packCurrency.forEach(element => {
                types.push(element.name);
            });
            return types;
        },
        packCurrencyIcons() {
            var icons = [];
            store.getters.packCurrency.forEach(element => {
                icons.push(element.icon);
            });
            return icons;
        },
        packCurrencyIndex() {
            return store.getters.packCurrencyIndex;
        },
    }
}
</script>