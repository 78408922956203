<template>
  <ul>
    <strong>
      Scenari
    </strong>
    <li v-for="(scenery, index) in sceneries" :key="index">
      <strong>{{ scenery.id + ' ' + scenery.name }}</strong>
    </li>
  </ul>
</template>

<script>
export default {
  name: "SideLegend",
  data() {
    return {
      sceneries: [
        {
          id: 10001,
          name: "Delta del Po"
        },
        {
          id: 10002,
          name: "Minsmere"
        },
        {
          id: 10003,
          name: "Everglades"
        },
        {
          id: 10004,
          name: "Kruger"
        },
        {
          id: 10005,
          name: "Hawaii"
        },
        {
          id: 10006,
          name: "Manu"
        }
      ]
    };
  }
};
</script>

<style scoped>
#help-tab {
  position: fixed;
  right: 55px;
  bottom: 10px;
  z-index: 100;
  padding: 13px;
  margin: 20px;
  background-color: var(--secondary-color);
  border-radius: 20px;
  border: 2px solid black;
  text-align: left;
  font-style: italic;
  cursor: move;
}
</style>
