<template>
	<div class="quest-body">
		<section class="quest-column">
			<header class="title-icon">
				<v-icon large >mdi-notification-clear-all</v-icon>
				<h1>Categorie missione</h1>
			</header>
			<div class="flex row align-items gap20 v-margin10">
				<div class="search-row">
					<p>
						<strong>Items:</strong>
					</p>
					<div style="width: 10px;"></div>
					<v-select v-model="quantity" solo :items="quantita" v-on:input="trier()" />
					<div style="width: 10px;"></div>
					<p>/ {{ questCategories.length }}</p>
				</div>
				<div class="flex row align-items space-between filter-bar">
					<div class="flex fit-content">
						<span v-on:click="proa('created_at')">Creazione</span>
						<v-icon>{{ order_creat ? 'mdi-menu-up' : 'mdi-menu-down' }}</v-icon>
					</div>
					<div class="flex fit-content">
						<span v-on:click="proa('updated_at')">Modifica</span>
						<v-icon>{{ order_update ? 'mdi-menu-up' : 'mdi-menu-down' }}</v-icon>
					</div>
					<div class="flex fit-content">
						<span v-on:click="proa('id')">ID</span>
						<v-icon>{{ order_id ? 'mdi-menu-up' : 'mdi-menu-down' }}</v-icon>
					</div>
				</div>
				<v-icon large  v-on:click="toggleSearch">
					mdi-search-web
				</v-icon>
			</div>
			<div class="pages-index">
				<span v-for="num in pages" :key="num" v-on:click="changePage(num)"
					:style="{ color: page === num ? '#b4f36d' : '#faebd7' }">{{ num }}</span>
			</div>
			<QuestCategoryTile
				v-for="( quest, index ) in questCategories.slice(((current_page - 1) * quantity), (current_page * quantity))"
				:key="index" :item="quest"
				@quest-category-clicked="selectQuestCategory(index + ((current_page - 1) * quantity))">
			</QuestCategoryTile>

		</section>
		<div>
			<section class="quest-column">
				<header>
					<div class="add-quest-bar">
						<h1>Aggiungi categoria missione</h1>
						<v-icon size="47" class="l-margin20 r-margin10" color="green" v-on:click="add = !add">{{ add ?
							'mdi-eye-off-outline' : 'mdi-eye-outline' }}</v-icon>
					</div>
				</header>
				<div v-if="add">
					<QuestCategoryCard :new="true" />
					<button class="new-quests-btn" v-on:click="createQuestCategories">CONFERMA</button>
				</div>
			</section>
			<section id="update_quest" class="quest-column">
				<header>
					<div class="add-quest-bar">
						<h1>Modifica o elimina categoria missione</h1>
						<v-icon size="47" class="l-margin20 r-margin10" color="green"
							v-on:click="isUpdateSet ? upd = !upd : null">{{ upd ?
								'mdi-eye-off-outline' : 'mdi-eye-outline' }}</v-icon>
					</div>
				</header>
				<div v-if="upd">
					<QuestCategoryCard :new="false" />
					<button class="new-quests-btn" v-on:click="undo()">ANNULLA</button>
					<button class="new-quests-btn" v-on:click="updateQuestCategories()">MODIFICA</button>
					<button class="new-quests-btn" v-on:click="cloneCategory()">CLONA</button>
					<button class="new-quests-btn" v-on:click="sureDelete()">ELIMINA</button>
				</div>
			</section>
			<div v-if="search" class="search-tools">
				<div class="cat-search-row">
					<p>
						Tipo:
					</p>
					<v-spacer></v-spacer>
					<v-select v-model="type_filter" solo :items="filterQuestCategoryTypes" v-on:change="findType()" />
				</div>
			</div>
		</div>
	</div>
</template>


<script>
import store from '@/store/index.js'

import QuestCategoryCard from '@/views/QuestCategory/QuestCategoryCard.vue';
import QuestCategoryTile from '@/views/QuestCategory/QuestCategoryTile.vue';

export default {
	name: 'EventQuestCategories',
	data: () => ({
		search: false,
		quantita: [15, 30, 50, 100],
		quantity: 15,
		event_id_filter: '',
		type_filter: '',
		cat_search: 9999,
		page: 1,
		pages: 0,
		order_creat: false,
		order_update: false,
		order_id: false,
		add: false,
		upd: false
	}),
	components: { QuestCategoryCard, QuestCategoryTile },
	computed: {
		isUpdateSet() {
			return store.getters.updateQuestCategory.id !== undefined;
		},
		token() {
			return store.getters.token;
		},
		questCategories() {
			var types = [];
			store.getters.filter_questCategories.forEach(element => {
				if (parseInt(element.event_id) === store.getters.updateEvent.id) {
					types.push(element);
				}
			});
			return types;
		},
		originalQuestCategories() {
			return store.getters.questCategories;
		},
		current_page() {
			return this.page;
		},
		questCategoryTypes() {
			return store.getters.questCategoryTypes;
		},
		filterQuestCategoryTypes() {
			var data = [
				"Nessuno"
			];
			store.getters.questCategoryTypes.forEach(element => {
				data.push(element);
			});
			return data;
		}
	},
	methods: {
		proa(column) {
			var flag = false;
			if (column === 'created_at') {
				this.order_creat = !this.order_creat
				flag = this.order_creat;
				this.questCategories.sort(function (a, b) {
					if (flag)
						return new Date(a[column]) > new Date(b[column]);
					else
						return new Date(a[column]) < new Date(b[column]);
				});
			}
			if (column === 'updated_at') {
				this.order_update = !this.order_update
				flag = this.order_update;
				this.questCategories.sort(function (a, b) {
					if (flag)
						return new Date(a[column]) > new Date(b[column]);
					else
						return new Date(a[column]) < new Date(b[column]);
				});
			}
			if (column === 'id') {
				this.order_id = !this.order_id
				flag = this.order_id;
				this.questCategories.sort(function (a, b) {
					if (flag)
						return a[column] > b[column];
					else
						return a[column] < b[column];
				});
			}
		},
		changePage(num) {
			this.page = num;
		},
		findType() {
			if (this.type_filter === 'Nessuno')
				this.cat_search = 9999;
			else
				this.cat_search = this.questCategoryTypes.indexOf(this.type_filter);

			this.filterList();
		},
		trier() {
			var main = Math.floor(this.questCategories.length / this.quantity);
			var resto = this.questCategories.length % this.quantity;
			this.pages = main + (resto > 0 ? 1 : 0);
			this.page = 1;
		},
		toggleSearch() {
			this.search = !this.search;
			if (!this.search)
				return;
		},
		selectQuestCategory(index) {
			store.dispatch('setUpdateQuestCategory', this.questCategories[index]);
			if (!this.upd)
				this.upd = true;
		},
		undo() {
			this.resetUpdate();
			this.upd = false;
		},
		resetUpdate() {
			store.dispatch('setUpdateQuestCategory', {});
			store.dispatch('setUpdateChosenQuestCategoryType', '');
			this.upd = false;
		},
		resetNormal() {
			store.dispatch('setQuestCategory', {});
			store.dispatch('setChosenQuestCategoryType', '');
			this.add = false;
		},
		cloneCategory() {
			if (confirm("Clonare categoria e missioni relazionate?"))
				this.cloneQuestCategories();

		},
		sureDelete() {
			let text = "Se confermi saranno permanentemente eliminate tutte le missioni legate a questa categoria. Gli eventi in corso degli utenti saranno danneggiati.";
			if (confirm(text))
				this.deleteQuestCategories();
		},
		async createQuestCategories() {
			store.dispatch('createQuestCategoryRequest', this.resetNormal);
		},
		async updateQuestCategories() {
			store.dispatch('updateQuestCategoryRequest', this.resetUpdate);
		},
		async deleteQuestCategories() {
			store.dispatch('deleteQuestCategoryRequest', this.resetUpdate);
		},
		async cloneQuestCategories() {
			store.dispatch('cloneQuestCategoryRequest', {
				callback: this.resetUpdate
			});
		},
		filterList() {
			store.dispatch('setFilterQuestCategories', this.originalQuestCategories.filter(quest =>
			(
				(this.cat_search === 9999 ? true : quest.type === this.cat_search)
			)
			));
			this.trier();
		},
	},
	mounted() {
		var main = Math.floor(this.questCategories.length / this.quantity);
		var resto = this.questCategories.length % this.quantity;
		this.pages = main + (resto > 0 ? 1 : 0);
	}
}
</script>


<style></style>