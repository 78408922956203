<template>
    <div class="flex column" style="color: bisque;">
        <h2 class="v-margin30">Acquisti</h2>
        <div class="flex row">
            <table style="flex: 1;">
                <thead>
                    <tr>
                        <th>Email</th>
                        <th><v-icon>mdi-currency-eur</v-icon></th>
                        <th>Acquistato alle</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(purchase, index) in purchaseds" :key="index">
                        <td><strong>{{ purchase.email }} </strong></td>
                        <td><strong>{{ prices[purchase.product_id] }} </strong></td>
                        <td><strong>{{ purchase.purchase_time }} </strong></td>
                    </tr>
                </tbody>
            </table>
            <section class="prova">
                <header>
                    <h2
                        style="display: flex; align-items: center; width: fit-content; margin: auto; margin-bottom: 30px;">
                        Sommario
                    </h2>
                </header>
                <div style="margin: 20px 10px; display: flex; align-items: center;">
                    <strong>Totale: {{ total }} </strong> <v-icon style="margin-left: 5px;">mdi-currency-eur</v-icon>
                </div>
                <div style="margin: 20px 10px; width: fit-content;  text-align: start;">
                    <strong>Utenti online: {{ online_users.length }} </strong>
                    <ul v-for="(user, index) in online_users" :key="index" style="background-color: var(--primary-color); border-radius: 10px; margin: 5px 7px; padding: 5px 20px;">
                        <strong>{{ user.email }}</strong>
                        <li>
                            Inizio: {{ sessiond[user.id]['init'] }}
                        </li>

                        <li>
                            Fine: {{ sessiond[user.id]['end'] }}
                        </li>
                    </ul>
                </div>
            </section>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import store from "@/store";

export default {
    name: "HomePage",
    data() {
        return {
            onlines: [],
            purchases: [],
            sessions: [],
            prices: {
                fixed_01: 1.99,
                fixed_02: 4.99,
                fixed_03: 9.99,
                fixed_04: 19.99,
                fixed_05: 34.99,
                fixed_06: 49.99,
                offer_04: 0.99,
                offer_01: 1.99,
                offer_02: 2.99,
                offer_03: 3.99,
                offer_05: 4.99,
                offer_06: 9.99,
            },
            totale: 0
        };
    },
    methods: {
        async getInfo() {
            await axios
                .get('api/editor/purchases/get')
                .then((res) => {
                    if (res.data.status) {
                        this.purchases = res.data.data.purchases;
                        this.totale = 0;
                        this.purchases.forEach(element => {
                            this.totale += this.prices[element.product_id]
                        });
                        this.totale = this.totale.toFixed(2)
                    }
                    else {
                        store.dispatch('setResponse', false);
                        store.dispatch('setSnackbarText', res.data.data ?? "Errore");
                        store.dispatch('setSnackbar', true);
                    }
                })
                .catch((error) => {
                    store.dispatch('setResponse', false);
                    store.dispatch('setSnackbarText', error.response.data.data ?? "Errore: " + error);
                    store.dispatch('setSnackbar', true);
                });
        },
        async getSessions() {
            await axios
                .get('api/editor/online/get')
                .then((res) => {
                    if (res.data.status) {
                        this.onlines = res.data.data.online;
                        this.sessions = res.data.data.sessions;
                    }
                    else {
                        store.dispatch('setResponse', false);
                        store.dispatch('setSnackbarText', res.data.data ?? "Errore");
                        store.dispatch('setSnackbar', true);
                    }
                })
                .catch((error) => {
                    store.dispatch('setResponse', false);
                    store.dispatch('setSnackbarText', error.response.data.data ?? "Errore: " + error);
                    store.dispatch('setSnackbar', true);
                });
        },
    },
    computed: {
        index() {
            return this.index;
        },
        online_users() {
            return this.onlines;
        },
        purchaseds() {
            return this.purchases;
        },
        total() {
            return this.totale;
        },
        sessiond() {
            return this.sessions;
        }
    },
    mounted() {
        this.getInfo();
        this.getSessions();
    },
}
</script>
<style>
.prova {
    flex: 1;
    max-width: 800px;
    background-color: var(--secondary-color);
    border-radius: 20px;
    height: fit-content;
    padding-top: 15px;
}
</style>