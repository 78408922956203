<template>
	<div>
		<QuestCategoryBar></QuestCategoryBar>
		<div class="quest-body">
			<section class="quest-column">
				<header class="title-icon">
					<v-icon large>mdi-file-tree</v-icon>
					<h1>Missioni</h1>
				</header>
				<div class="flex row align-items gap20 v-margin10">
					<div class="search-row">
						<p>
							<strong>Items:</strong>
						</p>
						<div style="width: 10px;"></div>
						<v-select v-model="quantity" solo :items="quantita" v-on:input="trier()" />
						<div style="width: 10px;"></div>
						<p>/ {{ filter_quests.length }}</p>
					</div>
					<div class="flex row align-items space-between filter-bar">
						<div class="flex fit-content">
							<span v-on:click="proa('created_at')">Creazione</span>
							<v-icon>{{ order_creat ? 'mdi-menu-up' : 'mdi-menu-down' }}</v-icon>
						</div>
						<div class="flex fit-content">
							<span v-on:click="proa('updated_at')">Modifica</span>
							<v-icon>{{ order_update ? 'mdi-menu-up' : 'mdi-menu-down' }}</v-icon>
						</div>
						<div class="flex fit-content">
							<span v-on:click="proa('id')">ID</span>
							<v-icon>{{ order_id ? 'mdi-menu-up' : 'mdi-menu-down' }}</v-icon>
						</div>
					</div>
					<v-icon large v-on:click="toggleSearch">
						mdi-search-web
					</v-icon>
				</div>
				<div class="pages-index">
					<span v-for="num in pages" :key="num" v-on:click="changePage(num)"
						:style="{ color: page === num ? '#b4f36d' : '#faebd7' }">{{ num }}</span>
				</div>
				<QuestTile
					v-for="( quest, index ) in filter_quests.slice(((current_page - 1) * quantity), (current_page * quantity))"
					:key="index" :item="quest" @quest-clicked="selectQuest(index + ((current_page - 1) * quantity))">
				</QuestTile>

			</section>
			<div>
				<section class="quest-column">
					<header>
						<div class="add-quest-bar">
							<h1>Aggiungi missione</h1>
							<v-icon size="47" class="l-margin20 r-margin10" color="green" v-on:click="add = !add">{{ add
								?
								'mdi-eye-off-outline' : 'mdi-eye-outline' }}</v-icon>
						</div>
					</header>
					<div v-if="add">
						<QuestCard :new="true" />
						<button class="new-quests-btn" v-on:click="createQuests">CONFERMA</button>
					</div>
				</section>
				<section id="update_quest" class="quest-column">
					<header>
						<div class="add-quest-bar">
							<h1>Modifica o elimina missione</h1>
							<v-icon size="47" class="l-margin20 r-margin10" color="green"
								v-on:click="isUpdateSet ? upd = !upd : null">{{ upd ?
									'mdi-eye-off-outline' : 'mdi-eye-outline' }}</v-icon>
						</div>
					</header>
					<div v-if="upd">
						<QuestCard :new="false" />
						<button class="new-quests-btn" v-on:click="undo()">ANNULLA</button>
						<button class="new-quests-btn" v-on:click="updateQuests()">MODIFICA</button>
						<button class="new-quests-btn" v-on:click="cloneQuest()">CLONA</button>
						<button class="new-quests-btn" v-on:click="sureDelete()">ELIMINA</button>
					</div>
				</section>
				<div v-if="search" class="search-tools">
					<div class="search-row">
						<p>
							Range:
						</p>
						<v-spacer></v-spacer>
						<input type="text" name="input-range" v-model="range_filter">
					</div>
					<div class="search-row">
						<p>
							ID range:
						</p>
						<v-spacer></v-spacer>
						<input type="text" name="input-range-id" v-model="range_id_filter">
					</div>
					<div class="search-row">
						<p>
							Tipo:
						</p>
						<v-spacer></v-spacer>
						<input type="text" name="input-type" v-model="type_filter">
					</div>
					<div class="cat-search-row">
						<p>
							Categoria:
						</p>
						<v-spacer></v-spacer>
						<v-select v-model="category_filter" solo :items="typesNameSelect"
							v-on:change="findCategory()" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<script>
import store from '@/store/index.js'

import QuestCard from '@/views/Quest/QuestCard.vue';
import QuestTile from '@/views/Quest/QuestTile.vue';
import QuestCategoryBar from '@/components/QuestCategoryBar.vue';

export default {
	name: 'EventQuests',
	data: () => ({
		search: false,
		quantita: [15, 30, 50, 100],
		quantity: 15,
		range_filter: '',
		range_id_filter: '',
		category_filter: '',
		type_filter: '',
		cat_search: 9999,
		page: 1,
		pages: 0,
		order_creat: false,
		order_update: false,
		order_id: false,
		order_event_id: false,
		add: false,
		upd: false
	}),
	components: { QuestCard, QuestTile, QuestCategoryBar },
	computed: {
		isUpdateSet() {
			return store.getters.updateQuest.id !== undefined;
		},
		quests() {
			return store.getters.quests;
		},
		chosenListCategory() {
			return store.getters.chosenListCategory;
		},
		filter_quests() {
			var types = [];
			if (this.chosenListCategory === null) {
				store.getters.filter_quests.reverse().forEach(element => {
					if (parseInt(element.event_id) === store.getters.updateEvent.id) {
						types.push(element);
					}
				});
			} else {
				store.getters.filter_quests.reverse().forEach(element => {
					if (parseInt(element.event_id) === store.getters.updateEvent.id && element.category_id === this.chosenListCategory) {
						types.push(element);
					}
				});
			}
			return types;
		},
		current_page() {
			return this.page;
		},
		typesNameSelect() {
			return store.getters.questCategoryNameFilter;
		},
		typesName() {
			return store.getters.questTypesName;
		},
		currentEvent() {
			return store.getters.updateEvent;
		},
	},
	methods: {
		proa(column) {
			var flag = false;
			if (column === 'created_at') {
				this.order_creat = !this.order_creat
				flag = this.order_creat;
				this.filter_quests.sort(function (a, b) {
					if (flag)
						return new Date(a[column]) > new Date(b[column]);
					else
						return new Date(a[column]) < new Date(b[column]);
				});
			}
			if (column === 'updated_at') {
				this.order_update = !this.order_update
				flag = this.order_update;
				this.filter_quests.sort(function (a, b) {
					if (flag)
						return new Date(a[column]) > new Date(b[column]);
					else
						return new Date(a[column]) < new Date(b[column]);
				});
			}
			if (column === 'id') {
				this.order_id = !this.order_id
				flag = this.order_id;
				this.filter_quests.sort(function (a, b) {
					if (flag)
						return a[column] > b[column];
					else
						return a[column] < b[column];
				});
			}
		},
		changePage(num) {
			this.page = num;
		},
		findCategory() {
			if (this.category_filter === 'Nessuna')
				this.cat_search = 9999;
			else
				this.cat_search = this.typesName.indexOf(this.category_filter);
			this.filterList();
		},
		trier() {
			var main = Math.floor(this.filter_quests.length / this.quantity);
			var resto = this.filter_quests.length % this.quantity;
			this.pages = main + (resto > 0 ? 1 : 0);
			this.page = 1;
		},
		toggleSearch() {
			this.search = !this.search;
			if (!this.search)
				return;

			setTimeout(() => {
				document.getElementsByName("input-range")[0].addEventListener('input', this.filterList);
				document.getElementsByName("input-range-id")[0].addEventListener('input', this.filterList);
				document.getElementsByName("input-type")[0].addEventListener('input', this.filterList);
			}, 100);
		},
		selectQuest(index) {
			store.dispatch('setUpdateQuest', this.filter_quests[index]);
			if (!this.upd)
				this.upd = true;
		},
		findName(quest) {
			store.getters.questQuestCategories.forEach(element => {
				if (element.id === quest.category_id && element.type === this.cat_search) {
					return true;
				}
			});
			return false;
		},
		undo() {
			this.resetUpdate();
			this.upd = false;
		},
		resetUpdate() {
			store.dispatch('setUpdateQuest', {});
			store.dispatch('setUpdateChosenQuestRange', '');
			store.dispatch('setUpdateChosenQuestCategory', '');
			store.dispatch('setUpdateChosenQuestRarity', '');
			this.filterList();
			this.upd = false;
		},
		resetNormal() {
			store.dispatch('setQuest', {
				"available_times": 1
			});
			store.dispatch('setChosenQuestRange', '');
			store.dispatch('setChosenQuestCategory', '');
			store.dispatch('setChosenQuestRarity', '');
			this.filterList();
			this.add = false;
		},
		cloneQuest() {
			if (confirm("Clonare categoria e missioni relazionate?"))
				this.cloneQuests();

		},
		sureDelete() {
			let text = "Se confermi saranno permanentemente eliminate tutte le missioni in corso degli utenti.";
			if (confirm(text))
				this.deleteQuests();
		},
		async createQuests() {
			store.dispatch('createQuestRequest', this.resetNormal);
		},
		async updateQuests() {
			store.dispatch('updateQuestRequest', this.resetUpdate);
		},
		async deleteQuests() {
			store.dispatch('deleteQuestRequest', this.resetUpdate);
		},
		async cloneQuests() {
			store.dispatch('cloneQuestRequest', {
				callback: this.resetUpdate
			});
		},
		filterList() {
			store.dispatch('setFilterQuests', this.quests.filter(quest =>
			(
				quest.range.toString().includes(this.range_filter) &&
				quest.range_id.toString().includes(this.range_id_filter) &&
				quest.quest_type.toString().includes(this.type_filter) &&
				(this.cat_search === 9999 ? true : this.findName(quest))
			)
			));
			this.trier();
		},
	},
	mounted() {
		var main = Math.floor(this.filter_quests.length / this.quantity);
		var resto = this.filter_quests.length % this.quantity;
		this.pages = main + (resto > 0 ? 1 : 0);
	}
}
</script>


<style>
.add-quest-bar {
	display: flex;
	flex-direction: row;
	margin: 10px auto;
	align-items: center;
	justify-content: space-between;
}

.filter-bar {
	height: fit-content;
	background-color: var(--secondary-color);
	border-radius: 15px;
	color: white;
}

.search-tools {
	display: flex;
	flex-direction: column;
	background-color: var(--secondary-color);
	margin: 20px;
	padding: 10px;
	border-radius: 20px;
	border: 2px solid black;
	right: 0;
}

.pages-index {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
}

.pages-index span {
	font-family: 'Fredoka';
	font-weight: 600;
	color: #faebd7;
	cursor: pointer;
	min-width: 24px;
}


.search-row {
	position: relative;
	display: flex;
	flex-direction: row;
	margin: 10px;
	justify-content: space-around;
	align-items: center;
}

.search-row .v-input {
	max-width: 90px;
}

.search-row .v-input__control {
	font-family: 'Fredoka';
	font-weight: 600;
	max-height: 48px;
}

.search-row p {
	padding: 0;
	margin: 0;
	font-family: 'Fredoka';
	font-weight: 600;
	color: antiquewhite;
}

.cat-search-row {
	position: relative;
	display: flex;
	flex-direction: row;
	margin: 10px;
	justify-content: space-around;
	align-items: center;
}

.cat-search-row .v-input__control {
	font-family: 'Fredoka';
	font-weight: 600;
	max-height: 48px;
}

.cat-search-row p {
	padding: 0;
	margin: 0;
	font-family: 'Fredoka';
	font-weight: 600;
	color: antiquewhite;
}


.quest-column {
	border-radius: 20px;
	box-shadow: 0 5px 15px rgb(86, 193, 5);
	margin-top: 20px;
	margin-left: 10px;
	margin-right: 10px;
	margin-bottom: 20px;
	padding: 10px;
}

.quest-icon {
	display: flex;
	flex-direction: column;
	margin: 10px;
	min-width: 60px;
}

.new-quest-icon {
	display: flex;
	flex-direction: column;
	max-width: 250px;
}

.quest-card {
	margin: 5px;
	border: solid 3px;
	align-items: center;
	justify-content: space-around;
	min-width: 700px;
}


.quest-card strong {
	margin-right: 13px;
}

.quest-card .row {
	margin: 10px;
	align-items: center;
}

.quest-card input {
	max-width: 65px;
	min-width: 50px;
}

.title-icon {
	display: flex;
	flex-direction: row;
	gap: 20px;
	margin: 1rem;
}

.title-icon h1 {
	width: fit-content;
}


.table-header {
	background-color: antiquewhite;
	box-shadow: 0 3px 5px black;
}

.table-header th {
	padding: 2px;
}

.header-row {
	display: flex;
	flex-direction: row;
}

.div-tile {
	display: flex;
	flex-direction: row;
	margin: 2px;
}

.table-tile {
	background-color: cadetblue;
}

.new-quests-btn {
	width: 150px;
	height: 50px;
	background-color: antiquewhite;
	border-radius: 20px;
	border: 2px solid black;
}
</style>