<template>
  <div :style="{ pointerEvents: isDragging ? 'none' : 'auto' }" ref="draggableContainer" class="draggable"
    @mousedown="dragMouseDown">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "DraggableItem",
  data() {
    return {
      isDragging: false,
      positions: {
        clientX: 0,
        clientY: 0,
        movementX: 0,
        movementY: 0
      }
    };
  },
  methods: {
    dragMouseDown(event) {
      event.preventDefault();

      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;
      document.onmousemove = this.elementDrag;
      document.onmouseup = this.closeDragElement;
    },
    elementDrag(event) {
      event.preventDefault();

      this.positions.movementX = event.clientX - this.positions.clientX;
      this.positions.movementY = event.clientY - this.positions.clientY;
      if (this.positions.movementX != event.clientX || this.positions.movementY != event.clientY)
        this.isDragging = true;

      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;

      const draggable = this.$refs.draggableContainer;
      draggable.style.bottom = parseInt(draggable.style.bottom || 0) - this.positions.movementY + 'px';
      draggable.style.right = parseInt(draggable.style.right || 0) - this.positions.movementX + 'px';
    },
    closeDragElement() {
      this.isDragging = false;
      document.onmouseup = null;
      document.onmousemove = null;
    }
  }
};
</script>

<style scoped>
.draggable {
  position: relative;
  z-index: 50;
  cursor: move;
}
</style>
