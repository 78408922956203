<template>
    <v-card class="flex quest-card" :style="{ backgroundColor: !item.active ? '#f77070' : currentReward.id === item.id ? '#f3df2a' : '#f5e665' }" @click="handleClick">
        <v-card-text class="flex row">
            <div class="flex column gap10">
                <div class="event-row align-items">
                    <strong>{{ rewardTypes[item.type] }}</strong>
                    <v-icon class="special-icon" large>
                        {{ rewardTypeIcons[item.type] }}
                    </v-icon>
                </div>
                <div v-if="item.type === 1" class="flex row">
                    <strong>Livello minimo:</strong> <v-spacer></v-spacer> {{ item.config_type }}
                </div>
                <div v-if="item.type === 1" class="flex row">
                    <strong>Livello massimo:</strong> <v-spacer></v-spacer> {{ item.max_level }}
                </div>

                <div class="flex row">
                    <strong>Avatar:</strong> {{ item.avatar }}
                </div>
                <div class="flex row">
                    <strong>Bordo Avatar:</strong> {{ item.border }}
                </div>
                <div class="flex row">
                    <strong>Sfondo carta:</strong> {{ item.card }}
                </div>
                <div class="flex row">
                    <strong>Emoji:</strong> {{ item.emoji }}
                </div>
                <div class="flex row">
                    <strong>ID:</strong> {{ item.id ?? '0' }}
                </div>
            </div>
            <div class="flex column">
                <div class="flex row">
                    <strong>Disponibile fino a:</strong> {{ item.expire_at }}
                </div>

                <div class="flex row">
                    <strong>Pacchetti:</strong> {{ item.pack_id }}
                </div>

                <div class="flex row">
                    <strong>Uccelli:</strong> {{ item.birds }}
                </div>

                <div class="flex row">
                    <strong>Piume:</strong> {{ item.feathers }}
                </div>

                <div class="flex row">
                    <strong>Monete:</strong> {{ item.coins }}
                </div>

                <div class="flex row">
                    <strong>Monete duello:</strong> {{ item.duel_coins }}
                </div>

                <div class="flex row">
                    <strong>Monete speciali:</strong> {{ item.special_coins }}
                </div>
            </div>
            <div class="flex column">
                <div class="flex row">
                    <strong>Miglior foto:</strong> {{ item.better_photo }}
                </div>

                <div class="flex row">
                    <strong>Bird radar:</strong> {{ item.bird_radar }}
                </div>

                <div class="flex row">
                    <strong>Doppia foto:</strong> {{ item.double_photo }}
                </div>

                <div class="flex row">
                    <strong>Esposizione perfetta:</strong> {{ item.perfect_exposure }}
                </div>

                <div class="flex row">
                    <strong>Focus rapido:</strong> {{ item.rapid_focus }}
                </div>

                <div class="flex row">
                    <strong>Probabilità:</strong> {{ item.probability }}
                </div>


                <div class="flex row">
                    <strong>Fortuna:</strong> {{ item.luck }}
                </div>

                <div class="flex row">
                    <strong>Punti VIP:</strong> {{ item.vip_points }}
                </div>
                <span class="flex row">
                    <strong>Valore:</strong> {{ item.value }}
                </span>
            </div>
        </v-card-text>
    </v-card>
</template>
<script>

import store from '@/store/index.js'
export default {
    name: "RewardsTile",
    data() {
        return {
        }
    },
    props: { item: Object },
    methods: {
        handleClick() {
            this.$emit('reward-clicked');
        },
    },
    computed: {
        currentReward(){
            return store.getters.updateReward;
        },
        rewardTypes() {
            var types = [];
            store.getters.rewardTypes.forEach(element => {
                types.push(element.name);
            });
            return types;
        },
        rewardTypeIcons() {
            var icons = [];
            store.getters.rewardTypes.forEach(element => {
                icons.push(element.icon);
            });
            return icons;
        },
    },
}
</script>
<style></style>