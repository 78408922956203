<template>
	<div class="quest-body">
		<section class="quest-column">
			<header class="title-icon">
				<v-icon large>mdi-store</v-icon>
				<h1>Prodotti</h1>
			</header>
			<div class="flex row align-items gap20 v-margin10">
				<div class="search-row">
					<p>
						<strong>Items:</strong>
					</p>
					<div style="width: 10px;"></div>
					<v-select v-model="quantity" solo :items="quantita" v-on:input="trier()" />
					<div style="width: 10px;"></div>
					<p>/ {{ filter_products.length }}</p>
				</div>
				<div class="flex row align-items space-between filter-bar">
					<div class="flex fit-content">
						<span v-on:click="reOrder('created_at')">Creazione</span>
						<v-icon>{{ order_creat ? 'mdi-menu-up' : 'mdi-menu-down' }}</v-icon>
					</div>
					<div class="flex fit-content">
						<span v-on:click="reOrder('updated_at')">Modifica</span>
						<v-icon>{{ order_update ? 'mdi-menu-up' : 'mdi-menu-down' }}</v-icon>
					</div>
					<div class="flex fit-content">
						<span v-on:click="reOrder('id')">ID</span>
						<v-icon>{{ order_id ? 'mdi-menu-up' : 'mdi-menu-down' }}</v-icon>
					</div>
				</div>
				<v-icon large v-on:click="toggleSearch">
					mdi-search-web
				</v-icon>
			</div>
			<div class="pages-index">
				<span v-for="num in pages" :key="num" v-on:click="changePage(num)"
					:style="{ color: page === num ? '#b4f36d' : '#faebd7' }">{{ num }}</span>
			</div>
			<ProductTile
				v-for="( product, index ) in filter_products.slice(((current_page - 1) * quantity), (current_page * quantity))"
				:key="index" :item="product" @product-clicked="selectProduct(index + ((current_page - 1) * quantity))">
			</ProductTile>

		</section>
			<div id="sider">
				<section class="quest-column">
					<header>
						<div class="add-quest-bar">
							<h1>Aggiungi prodotto</h1>
							<v-icon size="47" class="l-margin20 r-margin10" color="green" v-on:click="add = !add">{{ add
								?
								'mdi-eye-off-outline' : 'mdi-eye-outline' }}</v-icon>
						</div>
					</header>
					<div v-if="add">
						<ProductCard :new="true" />
						<button class="new-quests-btn" v-on:click="createProducts">CONFERMA</button>
					</div>
				</section>
				<section id="update_quest" class="quest-column">
					<header>
						<div class="add-quest-bar">
							<h1>Modifica o elimina prodotto</h1>
							<v-icon size="47" class="l-margin20 r-margin10" color="green"
								v-on:click="isUpdateSet ? upd = !upd : null">{{ upd ?
									'mdi-eye-off-outline' : 'mdi-eye-outline' }}</v-icon>
						</div>
					</header>
					<div v-if="upd">
						<ProductCard :new="false" />
						<button class="new-quests-btn" v-on:click="undo()">ANNULLA</button>
						<button class="new-quests-btn" v-on:click="duplicateProducts()">DUPLICA</button>
						<button class="new-quests-btn" v-on:click="updateProducts()">MODIFICA</button>
						<button class="new-quests-btn" v-on:click="sureDelete()">ELIMINA</button>
					</div>
				</section>
				<div v-if="search" class="search-tools">
					<div class="search-row">
						<p>
							ID:
						</p>
						<v-spacer></v-spacer>
						<input type="text" id="input-id" v-on:input="setIdfilter()">
					</div>
					<div class="search-row">
						<p>
							SKU:
						</p>
						<v-spacer></v-spacer>
						<input type="text" id="input-SKU" v-on:input="setSkufilter()">
					</div>
					<div class="search-row">
						<p>
							Pacchetti:
						</p>
						<v-spacer></v-spacer>
						<input type="text" id="input-packs" v-on:input="setPackfilter()">
					</div>
					<div class="search-row">
						<p>
							Row ID:
						</p>
						<v-spacer></v-spacer>
						<input type="text" id="input-row-id" v-on:input="setRowIdfilter()">
					</div>
					<div class="search-row">
						<p>
							Nascondi inattivi:
						</p>
						<v-spacer></v-spacer>
						<input type="checkbox" v-on:input="setActivefilter()" id="input-active-prod">
					</div>
				</div>
			</div>
	</div>
</template>


<script>
import store from '@/store/index.js'
import ProductCard from '@/views/Product/ProductCard.vue';
import ProductTile from '@/views/Product/ProductTile.vue';

export default {
	name: 'ProductsView',
	data: () => ({
		search: false,
		quantita: [15, 30, 50, 100],
		quantity: 15,
		cat_search: 9999,
		page: 1,
		pages: 0,
		order_creat: false,
		order_update: false,
		order_id: false,
		order_event_id: false,
		add: false,
		upd: false,
	}),
	components: { ProductCard, ProductTile },
	computed: {
		isUpdateSet() {
			return store.getters.updateProduct.id !== undefined;
		},
		token() {
			return store.getters.token;
		},
		products() {
			return store.getters.products;
		},
		filter_products() {
			return store.getters.filter_products;
		},
		current_page() {
			return this.page;
		},
		id_filter() {
			return store.getters.getIDfilter;
		},
		sku_filter() {
			return store.getters.getSKUfilter;
		},
		pack_id_filter() {
			return store.getters.getPackfilter;
		},
		row_id_filter() {
			return store.getters.getRowIDfilter;
		},
		active_filter() {
			return store.getters.getActivefilter;
		},
	},
	methods: {
		setRowIdfilter() {
			store.dispatch('setRowIdfilter', document.getElementById("input-row-id").value);
			this.filterList();
		},
		setActivefilter() {
			store.dispatch('setActivefilter', document.getElementById("input-active-prod").checked);
			this.filterList();
		},
		setSkufilter() {
			store.dispatch('setSKUfilter', document.getElementById("input-SKU").value);
			this.filterList();
		},
		setPackfilter() {
			store.dispatch('setPackfilter', document.getElementById("input-packs").value);
			this.filterList();
		},
		setIdfilter() {
			store.dispatch('setIdfilter', document.getElementById("input-id").value);
			this.filterList();
		},
		reOrder(column) {
			var flag = false;
			if (column === 'created_at') {
				this.order_creat = !this.order_creat
				flag = this.order_creat;
				this.filter_products.sort(function (a, b) {
					if (flag)
						return new Date(a[column]) > new Date(b[column]);
					else
						return new Date(a[column]) < new Date(b[column]);
				});
			}
			if (column === 'updated_at') {
				this.order_update = !this.order_update
				flag = this.order_update;
				this.filter_products.sort(function (a, b) {
					if (flag)
						return new Date(a[column]) > new Date(b[column]);
					else
						return new Date(a[column]) < new Date(b[column]);
				});
			}
			if (column === 'id') {
				this.order_id = !this.order_id
				flag = this.order_id;
				this.filter_products.sort(function (a, b) {
					if (flag)
						return a[column] > b[column];
					else
						return a[column] < b[column];
				});
			}
			if (column === 'event_id') {
				this.order_event_id = !this.order_event_id
				flag = this.order_event_id;
				this.filter_products.sort(function (a, b) {
					if (flag)
						return a[column] > b[column];
					else
						return a[column] < b[column];
				});
			}
		},
		changePage(num) {
			this.page = num;
		},
		trier() {
			var main = Math.floor(this.filter_products.length / this.quantity);
			var resto = this.filter_products.length % this.quantity;
			this.pages = main + (resto > 0 ? 1 : 0);
			this.page = 1;
		},
		toggleSearch() {
			this.search = !this.search;
		},
		selectProduct(index) {
			store.dispatch('setUpdateProduct', this.filter_products[index]);
			if (!this.upd)
				this.upd = true;
		},
		undo() {
			this.resetUpdate();
			this.upd = false;
		},
		resetUpdate() {
			store.dispatch('setUpdateProduct', {});
			store.dispatch('setUpdateChosenProductType', '');
			store.dispatch('setUpdateChosenProductCurrency', '');
			this.upd = false;
		},
		resetNormal() {
			store.dispatch('setProduct', {
				'id': 0,
				'sku': 'offer_01 (a pagamento)',
				'active': 0,
				'type': 0,
				'unlock_level': 0,
				'expire_at': '2024-03-25 00:00:00',
				'available_from': '2024-01-25 00:00:00',
				'seconds_to_open': 0,
				'pack_id': 'nome_pacchetto',
				'coins': 0,
				'special_coins': 0,
				'feathers': 0,
				'vip_points': 0,
				'better_photo': 0,
				'rapid_focus': 0,
				'probability': 0,
				'bird_radar': 0,
				'luck': 0,
				'perfect_exposure': 0,
				'double_photo': 0,
				'birds': 0,
				'currency': 0,
				'amount': 0,
				'row_id': 'row_id',
				'avatar': 0,
				'border': 0,
				'card': 0,
				'emoji': 0
			});
			store.dispatch('setChosenProductType', '');
			store.dispatch('setChosenProductCurrency', '');
			this.add = false;
		},
		async duplicateProducts() {
			store.dispatch('duplicateProductRequest', this.resetUpdate);
		},
		async createProducts() {
			store.dispatch('createProductRequest', this.resetNormal);
		},
		async updateProducts() {
			store.dispatch('updateProductRequest', this.resetUpdate);
		},
		sureDelete() {
			let text = "Se confermi saranno permanentemente eliminati anche i prodotti degli utenti legati a questo prodotto.";
			if (confirm(text))
				this.deleteProducts();
		},
		async deleteProducts() {
			store.dispatch('deleteProductRequest', this.resetUpdate);
		},
		filterList() {
			store.dispatch('filterList');
			this.trier();
		},
	},
	mounted() {
		var main = Math.floor(this.filter_products.length / this.quantity);
		var resto = this.filter_products.length % this.quantity;
		this.pages = main + (resto > 0 ? 1 : 0);
	}
}
</script>


<style>
.add-product-bar {
	display: flex;
	flex-direction: row;
	margin: 10px auto;
	align-items: center;
	justify-content: space-between;
}

.filter-bar {
	height: fit-content;
	background-color: var(--secondary-color);
	border-radius: 15px;
	color: white;
}

.search-tools {
	display: flex;
	flex-direction: column;
	background-color: var(--secondary-color);
	margin: 20px;
	padding: 10px;
	border-radius: 20px;
	border: 2px solid black;
	right: 0;
}

.pages-index {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
}

.pages-index span {
	font-family: 'Fredoka';
	font-weight: 600;
	color: #faebd7;
	cursor: pointer;
	min-width: 24px;
}


.search-row {
	position: relative;
	display: flex;
	flex-direction: row;
	margin: 10px;
	justify-content: space-around;
	align-items: center;
}

.search-row .v-input {
	max-width: 90px;
}

.search-row .v-input__control {
	font-family: 'Fredoka';
	font-weight: 600;
	max-height: 48px;
}

.search-row p {
	padding: 0;
	margin: 0;
	font-family: 'Fredoka';
	font-weight: 600;
	color: antiquewhite;
}

.cat-search-row {
	position: relative;
	display: flex;
	flex-direction: row;
	margin: 10px;
	justify-content: space-around;
	align-items: center;
}

.cat-search-row .v-input__control {
	font-family: 'Fredoka';
	font-weight: 600;
	max-height: 48px;
}

.cat-search-row p {
	padding: 0;
	margin: 0;
	font-family: 'Fredoka';
	font-weight: 600;
	color: antiquewhite;
}


.quest-column {
	border-radius: 20px;
	box-shadow: 0 5px 15px rgb(86, 193, 5);
	margin-top: 20px;
	margin-left: 10px;
	margin-right: 10px;
	margin-bottom: 20px;
	padding: 10px;
}

.quest-icon {
	display: flex;
	flex-direction: column;
	margin: 10px;
	min-width: 60px;
}

.new-quest-icon {
	display: flex;
	flex-direction: column;
	max-width: 250px;
}

.title-icon {
	display: flex;
	flex-direction: row;
	gap: 20px;
	margin: 1rem;
}

.title-icon h1 {
	width: fit-content;
}


.table-header {
	background-color: antiquewhite;
	box-shadow: 0 3px 5px black;
}

.table-header th {
	padding: 2px;
}

.header-row {
	display: flex;
	flex-direction: row;
}

.div-tile {
	display: flex;
	flex-direction: row;
	margin: 2px;
}

.table-tile {
	background-color: cadetblue;
}

.new-quests-btn {
	width: 150px;
	height: 50px;
	background-color: antiquewhite;
	border-radius: 20px;
	border: 2px solid black;
}
</style>