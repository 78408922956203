<template>
    <v-card class="flex quest-card" :style="{ backgroundColor: !item.active ? '#f77070' : currentQuestCategory.id === item.id ? '#85eb0f' : '#b4f36d' }" @click="handleClick">
        <v-card-text class="flex row">
            <div class="flex column">
                <strong>{{ questCategoryTypes[item.type] }}</strong>
                <v-spacer></v-spacer>
                <div class="flex row t-margin30">
                    <strong>ID:</strong> <v-spacer></v-spacer> {{ item.id }}
                </div>
                <div class="flex row">
                    <strong>ID evento:</strong> <v-spacer></v-spacer> {{ item.event_id }}
                </div>
            </div>
            <div class="flex column">
                <div class="flex row">
                    <strong>Categoria abbinata:</strong> <v-spacer></v-spacer> {{ item.related_category }}
                </div>
                <div class="flex row">
                    <strong>Secondi per sblocco:</strong> <v-spacer></v-spacer> {{ item.seconds_to_unlock }}
                </div>
                <div class="flex row">
                    <strong>Secondi per blocco:</strong> <v-spacer></v-spacer> {{ item.seconds_to_lock }}
                </div>
                <div class="flex row">
                    <strong>Tipo sblocco:</strong> <v-spacer></v-spacer> {{ item.unlock_type }}
                </div>
                <div class="flex row">
                    <strong>Configurazione sblocco:</strong> <v-spacer></v-spacer> {{ item.unlock_type_config }}
                </div>
            </div>
        </v-card-text>
    </v-card>
</template>
<script>

import store from '@/store/index.js'
export default {
    name: "QuestCategoryTile",
    data() {
        return {
        }
    },
    props: { item: Object },
    methods: {
        handleClick() {
            this.$emit('quest-category-clicked', this.item);
        },
    },
    computed: {
        currentQuestCategory() {
            return store.getters.updateQuestCategory
        },
        questCategoryTypes() {
            return store.getters.questCategoryTypes;
        }
    },
}
</script>
<style>
</style>