<template>
    <div class="stat-column" v-on:click="handleClick">
        <div class="main-box">
            <div class="icon-box">
                <div v-for="(icon, index) in icons" :key="index" class="little-box">
                    <v-icon color="black" large @mouseover="showDescription(index)"
                        @mouseleave="hideDescription()">mdi-{{ icon.nome
                        }}</v-icon>
                    <div v-if="hoverIndex === index" class="icon-description">{{ icon.description }}</div>
                    <span v-if="index === 0">{{ valore }}</span>
                    <span v-if="index === 1">{{ media }}</span>
                    <span v-if="index === 2">{{ minimo }}</span>
                    <span v-if="index === 3">{{ massimo }}</span>
                </div>
            </div>
        </div>
        <div class="scenery-banner">
            <h3 style="color: white;">
                {{ nome }}
            </h3>
        </div>
    </div>
</template>
<script>
export default {
    name: "SceneryBox",
    data() {
        return {
            hoverIndex: -1,
            icons: [
                { nome: 'account', description: 'Totale utenti' },
                { nome: 'sigma', description: 'Media livelli' },
                { nome: 'less-than', description: 'Livello minimo' },
                { nome: 'greater-than', description: 'Livello massimo' },
            ],
        }
    },
    props: ['valore', 'nome', 'media', 'minimo', 'massimo', 'index'],
    methods: {
        handleClick() {
            this.$emit('onClick', this.index);
        },
        showDescription(index) {
            this.hoverIndex = index;
        },
        hideDescription() {
            this.hoverIndex = -1;
        },
    }
}
</script>
<style>
.main-box {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 275px;
    height: 170px;
    border-radius: 8px;
    margin: 5px auto;
    padding: 5px;
    background: #D9D9D9;
    align-items: flex-start;
}

.main-box span {
    font-size: 18px;
    font-weight: 600;
    color: rgb(61, 61, 61);
}

.icon-description {
    display: none;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #f0f0f0;
    border: 1px solid black;
    padding: 5px;
    z-index: 2;
    border-radius: 5px;
}

.little-box:hover .icon-description {
    display: block;
}

.icon-box {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 95%;
    justify-content: space-around;
    margin: auto;
}

.scenery-banner {
    position: relative;
    background-color: #BC6C25;
    width: 275px;
    margin: auto;
    border-radius: 8px;

}

.stat-column {
    position: relative;
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin-bottom: 10px;
}

.little-box {
    position: relative;
    display: flex;
    flex-direction: column;
    width: fit-content;
}
</style>