import axios from "axios";
import store from "./store";

export async function ping() {
  var sec = null;
  await axios
    .get('api/ping')
    .then((res) => {
      if (res.data.status) {
        sec = true;
      } else {
        sec = false;
      }
    })
    .catch(() => {
      sec = false;
    });
  return sec;
}

export async function changeEventsStatus(status) {
  axios
    .get('api/activate/all/event?active=' + (status ? '1' : '0'))
    .then((res) => {
      if (res.data.status) {
        window.location.reload();
      } else {
        store.dispatch('setResponse', false);
        store.dispatch('setSnackbarText', res.data.data);
        store.dispatch('setSnackbar', true);
      }
    });
}

export async function getEmails() {
  axios
    .get('api/emails/active')
    .then((res) => {
      if (res.data.status) {
        store.dispatch("setEmails", res.data.data);
      } else {
        store.dispatch("logOut");
        store.dispatch('setLogged', false);
      }
    });
}
export async function exportCSV() {
  try {
    const response = await axios.get('api/export/email/csv');

    // Extract filename from Content-Disposition header
    const contentDisposition = response.headers['content-disposition'];
    let filename = 'exported_data.csv'; // Default filename if header is not present

    if (contentDisposition) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(contentDisposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
    }

    // Create a blob URL and trigger download
    const url = window.URL.createObjectURL(new Blob([response.data]));

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename.trim());
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error('Error downloading CSV:', error);
  }
}

export async function getPack() {
  axios
    .get('api/packs/get')
    .then((res) => {
      if (res.data.status) {
        store.dispatch("setPacks", res.data.data);
      } else {
        store.dispatch("logOut");
        store.dispatch('setLogged', false);
      }
    })
    .catch(() => {
      store.dispatch("logOut");
      store.dispatch('setLogged', false);
    });
}

export async function getProduct() {
  await axios
    .get('api/products/get/')
    .then((res) => {
      if (res.data.status) {
        store.dispatch("setProducts", res.data.data);
        store.dispatch("setFilterProducts", res.data.data);
      } else {
        store.dispatch("logOut");
        store.dispatch('setLogged', false);
      }
    })
    .catch(() => {
      store.dispatch("logOut");
      store.dispatch('setLogged', false);
    });
}

export async function getReward() {
  await axios
    .get('api/rewards/get/')
    .then((res) => {
      if (res.data.status) {
        store.dispatch("setRewards", res.data.data);
      } else {
        store.dispatch("logOut");
        store.dispatch('setLogged', false);
      }
    })
    .catch(() => {
      store.dispatch("logOut");
      store.dispatch('setLogged', false);
    });
}

export async function getCodes() {
  await axios
    .get('api/codes/get/')
    .then((res) => {
      if (res.data.status) {
        store.dispatch("setCodes", res.data.data);
      } else {
        store.dispatch("logOut");
        store.dispatch('setLogged', false);
      }
    })
    .catch(() => {
      store.dispatch("logOut");
      store.dispatch('setLogged', false);
    });
}

export async function getBirds() {
  axios
    .get('api/birds/get')
    .then((res) => {
      if (res.data.status) {
        store.dispatch("setBirds", res.data.data);
        store.dispatch("setFilterBirds", res.data.data);
      } else {
        store.dispatch("logOut");
        store.dispatch('setLogged', false);
      }
    })
    .catch(() => {
      store.dispatch("logOut");
      store.dispatch('setLogged', false);
    });
}

export async function getQuestions() {
  axios
    .get('api/questions/get')
    .then((res) => {
      if (res.data.status) {
        store.dispatch("setQuestions", res.data.data);
      } else {
        store.dispatch("logOut");
        store.dispatch('setLogged', false);
      }
    })
    .catch(() => {
      store.dispatch("logOut");
      store.dispatch('setLogged', false);
    });
}

export async function logOut() {
  await axios.post('api/editor/logout')
    .then((res) => {
      if (!res.data.status)
        return false;

      store.dispatch("logOut");
      store.dispatch("setLogged", false);
      return true;
    }).catch(() => {
      return false;
    });
}

export async function getFW() {
  axios.get('api/fortune/wheel/reward/get')
    .then((res) => {
      if (res.data.status) {
        store.dispatch("setFW", res.data.data);
      } else {
        store.dispatch("logOut");
        store.dispatch('setLogged', false);
      }
    })
    .catch(() => {
      store.dispatch("logOut");
      store.dispatch('setLogged', false);
    });
}

export async function getCheckpoints() {
  axios.get('api/fortune/wheel/checkpoint/get')
    .then((res) => {
      if (res.data.status) {
        store.dispatch("setCheckpoint", res.data.data['checkpoints']);
        store.dispatch("setRarities", res.data.data['rarities']);
        store.dispatch("setCheckpointLimit", res.data.data['checkpoint_limit']);
      } else {
        store.dispatch("logOut");
        store.dispatch('setLogged', false);
      }
    })
    .catch(() => {
      store.dispatch("logOut");
      store.dispatch('setLogged', false);
    });
}