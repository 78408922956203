<template>
	<div :key="componentKey" class="tables-container">
		<table v-for="item in item_types" :key="item" class="center-table">
			<thead>
				<tr>
					<th colspan="7">
						<h2>{{ item.replace('_', ' ') }}</h2>
					</th>
				</tr>
				<tr>
					<th v-on:click="sortList(item, 'products')">Prodotti</th>
					<th v-on:click="sortList(item, 'rewards')">Premi</th>
					<th v-on:click="sortList(item, 'packs')">Pacchetti</th>
					<th v-on:click="sortList(item, 'scenery')">Scenario</th>
					<th v-on:click="sortList(item, 'level')">Livello</th>
					<th v-on:click="sortList(item, 'rarity')">Rarita</th>
					<th v-on:click="sortList(item, 'value')">Valore</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(items, index) in sorted_items[item]" :key="index"
					:style="{ backgroundColor: (selectedItem == item && selectedIndex == index) ? 'rgb(9, 35, 3)' : '' }"
					@click="clicked(item, index)">
					<td><v-icon large :color="items.products ? 'green' : 'red'">mdi-{{ items.products ? 'check' :
						'selection-remove' }}</v-icon></td>
					<td><v-icon large :color="items.rewards ? 'green' : 'red'">mdi-{{ items.rewards ? 'check' :
						'selection-remove' }}</v-icon></td>
					<td><v-icon large :color="items.packs ? 'green' : 'red'">mdi-{{ items.packs ? 'check' :
						'selection-remove' }}</v-icon></td>
					<td><strong>{{ items.scenery }}</strong></td>
					<td><strong>{{ items.level }}</strong></td>
					<td><strong>{{ items.rarity }}</strong></td>
					<td><strong>{{ items.value }}</strong></td>
				</tr>
			</tbody>
		</table>
	</div>
</template>
<script>
import store from '@/store';
export default {
	name: "ItemList",
	data() {
		return {
			componentKey: 0,
			level: false,
			rarity: false,
			scenery: false,
			packs: false,
			rewards: false,
			products: false,
			value: false
		}
	},
	computed: {
		items() {
			return store.getters.items;
		},
		sorted_items() {
			return this.$store.getters.sorted_items;
		},
		item_types() {
			return store.getters.item_types;
		},
		selectedItem() {
			return store.getters.selectedItem;
		},
		selectedIndex() {
			return store.getters.selectedIndex;
		}
	},
	// watch: {
	// 	data() {
	// 		this.selectedItem = '';
	// 		this.selectedIndex = null;
	// 	}
	// },
	// props: { data: Boolean },
	methods: {
		clicked(item, index) {
			//var centerTable = document.getElementsByClassName('tables-container');
			var centerTable = document.getElementsByClassName('item-view-container');
			if (this.selectedItem == item && this.selectedIndex == index) {
				store.dispatch('setSelectedItem', { item: '', index: 0 });
				centerTable[0].style.justifyContent = 'space-around';
				//centerTable[0].style.margin = 'auto';
				// document.querySelectorAll('.center-table tbody td').forEach(td => {
				// 	td.style.width = '120px';
				// });
			} else {
				store.dispatch('setSelectedItem', { item: item, index: index });
				centerTable[0].style.justifyContent = 'space-between';
				//centerTable[0].style.margin = '0';
				// document.querySelectorAll('.center-table tbody td').forEach(td => {
				// 	td.style.width = '90px';
				// });
			}
		},
		forceRerender() {
			this.componentKey += 1;
			if (this.selectedItem == '') {
				var centerTable = document.getElementsByClassName('item-view-container');
				centerTable[0].style.justifyContent = 'space-around';
			}
		},
		sortList(item, filter) {
			let data = null;
			const tmp = this.sorted_items[item];
			switch (filter) {
				case 'level':
					this.level = !this.level;
					data = {
						item: item,
						list: tmp.sort((a, b) => this.level ? a.level - b.level : b.level - a.level)
					};
					break;
				case 'rarity':
					this.rarity = !this.rarity;
					data = {
						item: item,
						list: tmp.sort((a, b) => this.rarity ? a.rarity - b.rarity : b.rarity - a.rarity)
					};
					break;
				case 'scenery':
					this.scenery = !this.scenery;
					data = {
						item: item,
						list: tmp.sort((a, b) => this.scenery ? a.scenery - b.scenery : b.scenery - a.scenery)
					};
					break;
				case 'packs':
					this.packs = !this.packs;
					data = {
						item: item,
						list: tmp.sort((a, b) => this.packs ? a.packs - b.packs : b.packs - a.packs)
					};
					break;
				case 'rewards':
					this.rewards = !this.rewards;
					data = {
						item: item,
						list: tmp.sort((a, b) => this.rewards ? a.rewards - b.rewards : b.rewards - a.rewards)
					};
					break;
				case 'products':
					this.products = !this.products;
					data = {
						item: item,
						list: tmp.sort((a, b) => this.products ? a.products - b.products : b.products - a.products)
					};
					break;

				case 'value':
					this.value = !this.value;
					data = {
						item: item,
						list: tmp.sort((a, b) => this.value ? a.value - b.value : b.value - a.value)
					};
					break;
				default:
					break;
			}
			data.callback = this.forceRerender;
			store.dispatch('setSortedItems', data);
			//store.dispatch('setSelectedItem', { item: '', index: 0 });
		}
	},
}
</script>
<style>
.tables-container {
	position: relative;
	width: fit-content;
	margin: 0 5px;
}

.center-table {
	text-align: center;
	color: bisque;
	margin: 10px auto;

}

.center-table h2 {
	text-transform: capitalize
}

.center-table tr {
	height: 30px;
}

.center-table th {
	border: 1px solid black;
	background-color: rgb(148, 86, 11);
	border-radius: 7px;
}


.center-table tbody tr:hover {
	background-color: rgb(16, 69, 4);
}


.center-table tbody td {
	width: 120px;
}
</style>