<template>
    <section class="FW-container">
        <header class="fit-width center y-margin20">
            <h1>RUOTA DELLA FORTUNA</h1>
        </header>
        <v-tabs centered background-color="rgba(0,0,0,0)">
            <v-tab><span class="title-text-style">Ricompense</span></v-tab>
            <v-tab><span class="title-text-style">Rarita</span></v-tab>
            <v-tab><span class="title-text-style">Checkpoints</span></v-tab>

            <v-tab-item v-for="n in 3" :key="n">
                <v-container style="background-color: #283618;" fluid>
                    <FWRewardView v-if="n == 1" />
                    <FWRarityView v-if="n == 2" />
                    <FWCheckpointView v-if="n == 3" />
                </v-container>
            </v-tab-item>
        </v-tabs>
        <div class="side-legend">
			<table>
				<tr>
					<th style="background-color: rgb(94, 151, 80); border-radius: 10px;">CHECKPOINT</th>
					<th style="background-color: rgb(111, 227, 214); border-radius: 10px;">REWARD</th>
				</tr>
				<tr v-for="(check, index) in checkpoints" :key="index">
					<td style="background-color: rgb(94, 151, 80); border-radius: 10px;">
						{{ check.name }}
					</td>
					<td style="background-color: rgb(111, 227, 214); border-radius: 10px;">
						{{ check.reward }}
					</td>
				</tr>
			</table>
			<div><strong><span> {{checkpoints.length + "/" + limit }}</span></strong></div>
		</div>
    </section>
</template>

<script>
import FWRarityView from './FWRarityView.vue';
import FWRewardView from './FWRewardView.vue';
import FWCheckpointView from './FWCheckpointView.vue';
import { getFW, getCheckpoints } from '@/functions'
import store from '@/store/index.js'

//import store from '@/store'
export default {
    name: "FortuneWheel",
    data() {
        return {
            tab: null,
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        };
    },
    computed: {
		limit(){
			return store.getters.checkpoint_limit;
		},
		checkpoints() {
			var ch = [];

			for (var i in store.getters.checkpoint) {
				var tr = {};
				tr.name = i;
				tr.reward = store.getters.checkpoint[i];
				ch.push(tr);
			}
			return ch;
		},
    },
    methods: {},
    mounted() {
		getFW();
		getCheckpoints();
    },
    components: { FWRewardView, FWRarityView, FWCheckpointView }
};
</script>
<style>
.FW-container {
    position: relative;
    max-width: 1440px;
	margin: 20px auto;
}

.FW-container h1 {
    font-family: 'Cairo';
    font-weight: 900;
    color: #ffe4c4;
    text-align: flex-start;
}

.title-text-style {
    font-family: 'Cairo';
    font-weight: 900;
    color: #ffe4c4;
    text-align: flex-start;
}
.tab-div {
    background-color: transparent;
}
</style>