import { deleteFromQuest, updateFromQuest, createFromQuest } from '@/model_functions';
import axios from 'axios';

const questModule = {
  state: {
    quests: [],
    filter_quests: [],
    quest: {
      "available_times": 1
    },
    questQuestCategories: [],
    updateQuest: {},
    chosenQuestRange: '',
    chosenQuestCategory: '',
    chosenQuestRarity: '',
    chosenQuestClass: '',
    chosenQuestType: '',
    updateChosenQuestType: '',
    updateChosenQuestRange: '',
    updateChosenQuestCategory: '',
    updateChosenQuestRarity: '',
    updateChosenQuestClass: '',
    quest_class: [],
    questRangesIcons: {
      "Uccello": "mdi-bird",
      "Scenario": "mdi-map-marker",
      "Globale": "mdi-earth",
    },
    questRangesName: [
      "Uccello",
      "Scenario",
      "Globale"
    ],
    questRangesType: {
      "Uccello": 0,
      "Scenario": 1,
      "Globale": 2
    },
    questRarityName: [
      "Nessuno",
      "Comune",
      "Raro",
      "Epico",
      "Mitico",
      "Leggendario",
      "Speciale"
    ],
    questRarityType: {
      "Nessuno": null,
      "Comune": 0,
      "Raro": 1,
      "Epico": 2,
      "Mitico": 3,
      "Leggendario": 4,
      "Speciale": 5
    },
    questRarityFilter: {
      null: "Nessuno",
      0: "Comune",
      1: "Raro",
      2: "Epico",
      3: "Mitico",
      4: "Leggendario",
      5: "Speciale"
    },
    questTypesName: [
      "Day 1",
      "Day 2",
      "Day 3",
      "Day 4",
      "Day 5",
      "Day 6",
      "Day 7",
      "Birds",
      "Stars",
      "Duels",
      "Cameras",
      "Licenses",
      "Consumables",
      "Day 1-2",
      "Day 2-3",
      "Day 3-4",
      "Day 4-5",
      "Day 5-6",
      "Day 6-7",
      "Phase 1",
      "Phase 2",
      "Phase 3",
      "Phase 4",
      "Phase 5",
      "Phase 6",
      "Phase 7",
      "Final",
      "Gear",
      "Po Delta",
      "Minsmere",
      "Everglades",
      "Kruger",
      "Hawaii",
      "Manu",
      "Papua New Guinea",
      "Commons",
      "Rares",
      "Epics",
      "Mythics",
      "Legendaries",
      "Specials",
      "Extra",
    ],
    questCategoryNameFilter: [
      "Nessuna",
      "Day 1",
      "Day 2",
      "Day 3",
      "Day 4",
      "Day 5",
      "Day 6",
      "Day 7",
      "Birds",
      "Stars",
      "Duels",
      "Cameras",
      "Licenses",
      "Consumables",
      "Day 1-2",
      "Day 2-3",
      "Day 3-4",
      "Day 4-5",
      "Day 5-6",
      "Day 6-7",
      "Phase 1",
      "Phase 2",
      "Phase 3",
      "Phase 4",
      "Phase 5",
      "Phase 6",
      "Phase 7",
      "Final",
      "Gear",
      "Po Delta",
      "Minsmere",
      "Everglades",
      "Kruger",
      "Hawaii",
      "Manu",
      "Papua New Guinea",
      "Commons",
      "Rares",
      "Epics",
      "Mythics",
      "Legendaries",
      "Specials",
      "Extra",
    ],
  },
  getters: {
    quests(state) {
      return state.quests;
    },
    questQuestCategories(state) {
      return state.questQuestCategories;
    },
    filter_quests(state) {
      return state.filter_quests;
    },
    quest(state) {
      return state.quest;
    },
    updateQuest(state) {
      return state.updateQuest;
    },
    chosenQuestRange(state) {
      return state.chosenQuestRange;
    },
    chosenQuestCategory(state) {
      return state.chosenQuestCategory;
    },
    chosenQuestRarity(state) {
      return state.chosenQuestRarity;
    },
    chosenQuestClass(state) {
      return state.chosenQuestClass;
    },
    chosenQuestType(state) {
      return state.chosenQuestType;
    },
    updateChosenQuestType(state) {
      return state.updateChosenQuestType;
    },
    updateChosenQuestClass(state) {
      return state.updateChosenQuestClass;
    },
    updateChosenQuestRange(state) {
      return state.updateChosenQuestRange;
    },
    updateChosenQuestCategory(state) {
      return state.updateChosenQuestCategory;
    },
    updateChosenQuestRarity(state) {
      return state.updateChosenQuestRarity;
    },
    questClass(state) {
      return state.quest_class;
    },
    questTypes(state) {
      for (let index = 0; index < state.quest_class.length; index++) {
        const element = state.quest_class[index];
        if (element.name === state.chosenQuestClass) {
          return element.types;
        }

      }
      return [];
    },
    updateQuestTypes(state) {
      for (let index = 0; index < state.quest_class.length; index++) {
        const element = state.quest_class[index];
        if (element.name === state.updateChosenQuestClass) {
          return element.types;
        }

      }
      return [];
    },
    questRangesName(state) {
      return state.questRangesName;
    },
    questRangesIcons(state) {
      return state.questRangesIcons;
    },
    questRangesType(state) {
      return state.questRangesType;
    },
    questRarityName(state) {
      return state.questRarityName;
    },
    questRarityType(state) {
      return state.questRarityType;
    },
    questRarityFilter(state) {
      return state.questRarityFilter;
    },
    questTypesName(state) {
      return state.questTypesName;
    },
    questCategoryNameFilter(state) {
      return state.questCategoryNameFilter;
    },
  },
  mutations: {
    setQuests(state, payload) {
      state.quests = payload;
      state.filter_quests = payload;
    },
    setQuestQuestCategories(state, payload) {
      state.questQuestCategories = payload;
    },
    setQuestClass(state, payload) {
      state.quest_class = payload;
    },
    setFilterQuests(state, payload) {
      state.filter_quests = payload;
    },
    setQuest(state, payload) {
      state.quest = payload;
    },
    setUpdateQuest(state, payload) {
      state.updateQuest = payload;
      var isNull = false;
      if (payload.range == undefined || payload.quest_class === undefined)
        isNull = true;
      state.updateChosenQuestRange = isNull ? '' : state.questRangesName[payload.range];
      state.updateChosenQuestRarity = isNull ? '' : state.questRarityFilter[payload.rarity];
      state.questQuestCategories.forEach(element => {
        if (element.id === state.updateQuest.category_id)
          state.updateChosenQuestCategory = isNull ? '' : state.questTypesName[element.type];
      });
      state.updateChosenQuestClass = isNull ? '' : state.quest_class[payload.quest_class].name;
      if (!isNull)
        state.quest_class[payload.quest_class].types.forEach(element => {
          if (element.type === payload.quest_type)
            state.updateChosenQuestType = element.name;
        })
      else
        state.updateChosenQuestType = '';

    },
    setChosenQuestRange(state, payload) {
      state.chosenQuestRange = payload;
      state.quest.range = state.questRangesType[payload];
    },
    setChosenQuestCategory(state, payload) {
      state.chosenQuestCategory = payload;
    },
    setChosenQuestRarity(state, payload) {
      state.chosenQuestRarity = payload;
      state.quest.rarity = state.questRarityType[payload];
    },
    setChosenQuestType(state, name, payload) {
      state.chosenQuestType = name;
      state.quest.quest_class = payload;
    },
    setChosenQuestClass(state, payload) {
      state.chosenQuestClass = state.quest_class[payload].name;
      state.chosenQuestType = '';
    },
    setUpdateChosenQuestType(state, name) {
      state.updateChosenQuestType = name;
    },
    setUpdateChosenQuestClass(state, payload) {
      state.updateChosenQuestClass = state.quest_class[payload].name;
      state.updateChosenQuestType = '';
    },
    setUpdateChosenQuestRange(state, payload) {
      state.updateChosenQuestRange = payload;
    },
    setUpdateChosenQuestCategory(state, payload) {
      state.updateChosenQuestCategory = payload;
    },
    setUpdateChosenQuestRarity(state, payload) {
      state.updateChosenQuestRarity = payload;
    },
  },
  actions: {
    setFilterQuests({ commit }, payload) {
      commit('setFilterQuests', payload);
    },
    setQuest({ commit }, payload) {
      commit('setQuest', payload);
    },
    setUpdateQuest({ commit }, payload) {
      commit('setUpdateQuest', payload);
    },
    setChosenQuestRange({ commit }, payload) {
      commit('setChosenQuestRange', payload);
    },
    setChosenQuestCategory({ commit }, payload) {
      commit('setChosenQuestCategory', payload);
    },
    setChosenQuestRarity({ commit }, payload) {
      commit('setChosenQuestRarity', payload);
    },
    setChosenQuestClass({ commit }, payload) {
      commit('setChosenQuestClass', payload);
    },
    setChosenQuestType({ commit, getters }, payload) {
      getters.questTypes.forEach(element => {
        if (element.type === payload) {
          commit('setChosenQuestType', element.name, payload);
        }
      });
    },
    setUpdateChosenQuestType({ commit, getters }, payload) {
      getters.updateQuestTypes.forEach(element => {
        if (element.type === payload) {
          commit('setUpdateChosenQuestType', element.name);
        }
      });
    },
    setUpdateChosenQuestClass({ commit }, payload) {
      commit('setUpdateChosenQuestClass', payload);
    },
    setUpdateChosenQuestRange({ commit }, payload) {
      commit('setUpdateChosenQuestRange', payload);
    },
    setUpdateChosenQuestCategory({ commit }, payload) {
      commit('setUpdateChosenQuestCategory', payload);
    },
    setUpdateChosenQuestRarity({ commit }, payload) {
      commit('setUpdateChosenQuestRarity', payload);
    },
    async getQuestRequest({ commit }) {
      axios
        .get('api/quests/get')
        .then((res) => {
          if (res.data.status) {
            commit("setQuests", res.data.data.quest);
            commit("setQuestClass", res.data.data.types);
          } else {
            commit("logOut");
            commit('setLogged', false);
          }
        })
        .catch(() => {
          commit("logOut");
          commit('setLogged', false);
        });
    },
    async updateQuestRequest({ commit, state }, callback) {
      const q = state.updateQuest;
      q.range = state.questRangesType[state.updateChosenQuestRange];
      state.questQuestCategories.forEach(element => {
        if (element.event_id === parseInt(q.event_id) && state.questTypesName.indexOf(state.updateChosenQuestCategory) === element.type)
          q.category_id = element.id;
      });
      q.rarity = state.questRarityType[state.updateChosenQuestRarity];
      q.quest_class = state.quest_class[state.updateChosenQuestClass];
      state.quest_class.forEach(element => {
        if (element.name === state.updateChosenQuestClass)
          q.quest_class = element.class;
      });
      state.quest_class[q.quest_class].types.forEach(element => {
        if (element.name === state.updateChosenQuestType) {
          q.quest_type = element.type;
        }

      });
      await axios
        .put('api/quests/update/' + q.id, q)
        .then(() => {
          updateFromQuest(q);
          commit('setResponse', true);
          commit('setSnackbarText', "Modificata missione ID: " + q.id);
          commit('setSnackbar', true);
          callback();
        }
        ).catch((error) => {
          if (error.response) {
            commit('setResponse', false);
            commit('setSnackbarText', error.response.data.data ?? error);
            commit('setSnackbar', true);
          }
        });
    },
    async cloneQuestRequest({ commit, state }, payload) {
      const { callback } = payload;
      await axios
        .post('api/quests/clone/' + state.updateQuest.id)
        .then((res) => {
          if (res.data.status) {
            commit("setQuests", res.data.data);
            commit('setResponse', true);
            commit('setSnackbarText', "Clonata missione ID: " + state.updateQuest.id);
            commit('setSnackbar', true);
            callback();
          } else {
            commit('setResponse', false);
            commit('setSnackbarText', res.data.data);
            commit('setSnackbar', true);
          }
        }).catch((error) => {
          commit('setResponse', false);
          commit('setSnackbarText', error.response.data.data ?? error);
          commit('setSnackbar', true);
        });
    },
    async createQuestRequest({ commit, state }, callback) {
      const q = state.quest;
      q.range = state.questRangesType[state.chosenQuestRange];
      state.questQuestCategories.forEach(element => {
        if (element.event_id === parseInt(q.event_id) && state.questTypesName.indexOf(state.chosenQuestCategory) === element.type)
          q.category_id = element.id;
      });
      q.rarity = state.questRarityType[state.chosenQuestRarity];
      state.quest_class.forEach(element => {
        if (element.name === state.chosenQuestClass)
          q.quest_class = element.class;
      });
      state.quest_class[q.quest_class].types.forEach(element => {
        if (element.name === state.chosenQuestType)
          q.quest_type = element.type;
      });
      await axios
        .post('api/quests/create/', q)
        .then((res) => {
          if (res.data.status) {
            createFromQuest(res.data.data);
            commit('setResponse', true);
            commit('setSnackbarText', "Creata nuova missione");
            commit('setSnackbar', true);
            callback();
          } else {
            commit('setResponse', false);
            commit('setSnackbarText', res.data.data);
            commit('setSnackbar', true);
          }
        }).catch((error) => {
          if (error.response) {
            commit('setResponse', false);
            commit('setSnackbarText', error.response.data.data ?? error);
            commit('setSnackbar', true);
          }
        });
    },
    async deleteQuestRequest({ commit, state }, callback) {
      const q = state.updateQuest;
      await axios
        .delete('api/quests/delete/' + q.id)
        .then(() => {
          deleteFromQuest(q.id);
          commit('setResponse', true);
          commit('setSnackbarText', "Eliminate missione ID: " + q.id);
          commit('setSnackbar', true);
          callback();
        }).catch((error) => {
          if (error.response) {
            commit('setResponse', false);
            commit('setSnackbarText', error.response.data.data ?? error);
            commit('setSnackbar', true);
          }
        });
    }
  },
};

export default questModule;
