<template>
    <v-card class="event-card"
        :style="{ backgroundColor: currentReward.active ? '#f3df2a' : '#f77070' }">
        <v-card-text class="flex row align-items">
            <div class="flex column h-margin10 fit-heigth gap10">
                <v-select :value="chosenRewardType" solo :items="rewardTypes" v-on:change="setType">
                </v-select>
                <v-icon class="special-icon" large>
                    {{ rewardTypeIcons[rewardTypeIndex[chosenRewardType]] }}
                </v-icon>
                <div v-if="chosenRewardType === 'A livello'" class="event-row">
                    <strong>Livello minimo:</strong> <input v-model="currentReward.config_type" />
                </div>
                <div v-if="chosenRewardType === 'A livello'" class="event-row">
                    <strong>Livello massimo:</strong> <input v-model="currentReward.max_level" />
                </div>
                <div class="event-row">
                    <strong>ID:</strong> <v-spacer></v-spacer> <input v-model="currentReward.id" />
                </div>
                <div class="event-row">
                    <strong>Avatar:</strong> <input v-model="currentReward.avatar" />
                </div>
                <div class="event-row">
                    <strong>Bordo Avatar:</strong> <input v-model="currentReward.border" />
                </div>

                <div class="event-row">
                    <strong>Sfondo Carta:</strong> <input v-model="currentReward.card" />
                </div>

                <div class="event-row">
                    <strong>Emoji:</strong> <input v-model="currentReward.emoji" />
                </div>
                <div class="flex column">
                    <input type="checkbox" class="checkbox" v-model="currentReward.active" />
                    <strong style="margin: auto;">{{ currentReward.active ? 'Attivo' : 'Disattivo' }}</strong>
                </div>
            </div>
            <div class="flex column h-margin10 space-around">
                <div class="event-row">
                    <strong>Disponibile fino a:</strong> <v-spacer></v-spacer> <input v-model="currentReward.expire_at" />
                </div>

                <div class="event-row">
                    <strong>Pacchetti:</strong> <v-spacer></v-spacer> <input v-model="currentReward.pack_id" />
                </div>

                <div class="event-row">
                    <strong>Uccelli:</strong> <v-spacer></v-spacer> <input v-model="currentReward.birds" />
                </div>

                <div class="event-row">
                    <strong>Piume:</strong> <v-spacer></v-spacer> <input v-model="currentReward.feathers" />
                </div>

                <div class="event-row">
                    <strong>Monete:</strong> <v-spacer></v-spacer> <input v-model="currentReward.coins" />
                </div>

                <div class="event-row">
                    <strong>Monete duello:</strong> <v-spacer></v-spacer> <input v-model="currentReward.duel_coins" />
                </div>

                <div class="event-row">
                    <strong>Monete speciali:</strong> <v-spacer></v-spacer> <input v-model="currentReward.special_coins" />
                </div>
            </div>
            <div class="flex column h-margin10 space-around">
                <div class="event-row">
                    <strong>Miglior foto:</strong> <v-spacer></v-spacer> <input v-model="currentReward.better_photo" />
                </div>
                <div class="event-row">
                    <strong>Bird radar:</strong> <v-spacer></v-spacer> <input v-model="currentReward.bird_radar" />
                </div>
                <div class="event-row">
                    <strong>Doppia foto:</strong> <v-spacer></v-spacer> <input v-model="currentReward.double_photo" />
                </div>
                <div class="event-row">
                    <strong>Esposizione perfetta:</strong> <v-spacer></v-spacer> <input
                        v-model="currentReward.perfect_exposure" />
                </div>
                <div class="event-row">
                    <strong>Focus rapido:</strong> <v-spacer></v-spacer> <input v-model="currentReward.rapid_focus" />
                </div>
                <div class="event-row">
                    <strong>Probabilità:</strong> <v-spacer></v-spacer> <input v-model="currentReward.probability" />
                </div>
                <div class="event-row">
                    <strong>Fortuna:</strong> <v-spacer></v-spacer> <input v-model="currentReward.luck" />
                </div>
                <div class="event-row">
                    <strong>Punti VIP:</strong> <v-spacer></v-spacer> <input v-model="currentReward.vip_points" />
                </div>
            </div>
        </v-card-text>
    </v-card>
</template>
<script>

import store from '@/store/index.js'
export default {
    name: "RewardCard",
    data() {
        return {
        }
    },
    props: {
        new: Boolean
    },
    methods: {
        setType(value) {
            if (this.new)
                store.dispatch('setChosenRewardType', value);
            else
                store.dispatch('setUpdateChosenRewardType', value);
        },
    },
    computed: {
        currentReward() {
            return this.new ? store.getters.reward : store.getters.updateReward;
        },
        chosenRewardType() {
            return this.new ? store.getters.chosenRewardType : store.getters.updateChosenRewardType;
        },
        rewardTypes() {
            var types = [];
            store.getters.rewardTypes.forEach(element => {
                types.push(element.name);
            });
            return types;
        },
        rewardTypeIcons() {
            var icons = [];
            store.getters.rewardTypes.forEach(element => {
                icons.push(element.icon);
            });
            return icons;
        },
        rewardTypeIndex() {
            return store.getters.rewardTypesIndex;
        },
    }
}
</script>