<template>
	<div class="quest-body">
		<section class="quest-column">
			<header class="title-icon">
				<v-icon large >mdi-cards</v-icon>
				<h1>Pacchetti</h1>
			</header>
			<div class="flex row align-items gap20 v-margin10">
				<div class="search-row">
					<p>
						<strong>Items:</strong>
					</p>
					<div style="width: 10px;"></div>
					<v-select v-model="quantity" solo :items="quantita" v-on:input="trier()" />
					<div style="width: 10px;"></div>
					<p>/ {{ filter_packs.length }}</p>
				</div>
				<div class="flex row align-items space-between filter-bar">
					<div class="flex fit-content">
						<span v-on:click="reOrder('created_at')">Creazione</span>
						<v-icon>{{ order_creat ? 'mdi-menu-up' : 'mdi-menu-down' }}</v-icon>
					</div>
					<div class="flex fit-content">
						<span v-on:click="reOrder('updated_at')">Modifica</span>
						<v-icon>{{ order_update ? 'mdi-menu-up' : 'mdi-menu-down' }}</v-icon>
					</div>
					<div class="flex fit-content">
						<span v-on:click="reOrder('id')">ID</span>
						<v-icon>{{ order_id ? 'mdi-menu-up' : 'mdi-menu-down' }}</v-icon>
					</div>
				</div>
				<v-icon large  v-on:click="toggleSearch">
					mdi-search-web
				</v-icon>
			</div>
			<div class="pages-index">
				<span v-for="num in pages" :key="num" v-on:click="changePage(num)"
					:style="{ color: page === num ? '#b4f36d' : '#faebd7' }">{{ num }}</span>
			</div>
			<PackTile
				v-for="( pack, index ) in filter_packs.slice(((current_page - 1) * quantity), (current_page * quantity))"
				:key="index" :item="pack" @pack-clicked="selectPack(index + ((current_page - 1) * quantity))">
			</PackTile>

		</section>
		<div id="sider">
			<section class="quest-column">
				<header>
					<div class="add-quest-bar">
						<h1>Aggiungi pacchetto</h1>
						<v-icon size="47" class="l-margin20 r-margin10" color="green" v-on:click="add = !add">{{ add ?
							'mdi-eye-off-outline' : 'mdi-eye-outline' }}</v-icon>
					</div>
				</header>
				<div v-if="add">
					<PackCard :new="true" />
					<button class="new-quests-btn" v-on:click="createPacks">CONFERMA</button>
				</div>
			</section>
			<section id="update_quest" class="quest-column">
				<header>
					<div class="add-quest-bar">
						<h1>Modifica o elimina pacchetto</h1>
						<v-icon size="47" class="l-margin20 r-margin10" color="green"
							v-on:click="isUpdateSet ? upd = !upd : null">{{ upd ?
							'mdi-eye-off-outline' : 'mdi-eye-outline' }}</v-icon>
					</div>
				</header>
				<div v-if="upd">
					<PackCard :new="false" />
					<button class="new-quests-btn" v-on:click="undo()">ANNULLA</button>
					<button class="new-quests-btn" v-on:click="duplicatePacks()">DUPLICA</button>
					<button class="new-quests-btn" v-on:click="updatePacks()">MODIFICA</button>
					<button class="new-quests-btn" v-on:click="sureDelete()">ELIMINA</button>
				</div>
			</section>
			<div v-if="search" class="search-tools">
				<div class="search-row">
					<p>
						ID:
					</p>
					<v-spacer></v-spacer>
					<input type="text" id="input-id" v-on:input="setIdfilter()">
				</div>
				<div class="search-row">
					<p>
						Nome:
					</p>
					<v-spacer></v-spacer>
					<input type="text" id="input-nome" v-on:input="setNomefilter()">
				</div>
				<div class="search-row">
					<p>
						Scenario:
					</p>
					<v-spacer></v-spacer>
					<input type="text" id="input-scenery" v-on:input="setSceneryfilter()">
				</div>
				<div class="search-row">
					<p>
						Nascondi inattivi:
					</p>
					<v-spacer></v-spacer>
					<input type="checkbox" id="input-active-pack" v-on:input="setActivefilter()">
				</div>
			</div>
		</div>
	</div>
</template>


<script>
import store from '@/store/index.js'
import PackCard from '@/views/Pack/PackCard.vue';
import PackTile from '@/views/Pack/PackTile.vue';

export default {
	name: 'PacksView',
	data: () => ({
		search: false,
		quantita: [15, 30, 50, 100],
		quantity: 15,
		cat_search: 9999,
		page: 1,
		pages: 0,
		order_creat: false,
		order_update: false,
		order_id: false,
		add: false,
		upd: false,
	}),
	components: { PackCard, PackTile },
	computed: {
		isUpdateSet() {
			return store.getters.updatePack.id !== undefined;
		},
		token() {
			return store.getters.token;
		},
		packs() {
			return store.getters.packs;
		},
		filter_packs() {
			return store.getters.filter_packs;
		},
		current_page() {
			return this.page;
		},
		id_filter() {
			return store.getters.getPackIDfilter;
		},
		nome_filter() {
			return store.getters.getPackNomefilter;
		},
		scenery_id_filter() {
			return store.getters.getPackSceneryfilter;
		},
		active_filter() {
			return store.getters.getPackActivefilter;
		},
	},
	methods: {
		setNomefilter() {
			store.dispatch('setPackNomefilter', document.getElementById("input-nome").value);
			this.filterList();
		},
		setActivefilter() {
			store.dispatch('setPackActivefilter', document.getElementById("input-active-pack").checked);
			this.filterList();
		},
		setIdfilter() {
			store.dispatch('setPackIdfilter', document.getElementById("input-id").value);
			this.filterList();
		},
		setSceneryfilter() {
			store.dispatch('setPackSceneryIdfilter', document.getElementById("input-scenery").value);
			this.filterList();
		},
		reOrder(column) {
			var flag = false;
			if (column === 'created_at') {
				this.order_creat = !this.order_creat
				flag = this.order_creat;
				this.filter_packs.sort(function (a, b) {
					if (flag)
						return new Date(a[column]) > new Date(b[column]);
					else
						return new Date(a[column]) < new Date(b[column]);
				});
			}
			if (column === 'updated_at') {
				this.order_update = !this.order_update
				flag = this.order_update;
				this.filter_packs.sort(function (a, b) {
					if (flag)
						return new Date(a[column]) > new Date(b[column]);
					else
						return new Date(a[column]) < new Date(b[column]);
				});
			}
			if (column === 'id') {
				this.order_id = !this.order_id
				flag = this.order_id;
				this.filter_packs.sort(function (a, b) {
					if (flag)
						return a[column] > b[column];
					else
						return a[column] < b[column];
				});
			}
		},
		changePage(num) {
			this.page = num;
		},
		trier() {
			var main = Math.floor(this.filter_packs.length / this.quantity);
			var resto = this.filter_packs.length % this.quantity;
			this.pages = main + (resto > 0 ? 1 : 0);
			this.page = 1;
		},
		toggleSearch() {
			this.search = !this.search;
		},
		selectPack(index) {
			store.dispatch('setUpdatePack', this.filter_packs[index]);
			if (!this.upd)
				this.upd = true;
		},
		undo() {
			this.resetUpdate();
			this.upd = false;
		},
		resetUpdate() {
			store.dispatch('setUpdatePack', {});
			store.dispatch('setUpdateChosenPackCurrency', '');
			this.upd = false;
		},
		resetNormal() {
			store.dispatch('setPack', {
				"id": null,
				"active": true,
				"total_elements": 0,
				"min_coins": 0,
				"max_coins": 0,
				"min_feathers": 0,
				"max_feathers": 0,
				"commons": 0,
				"rares": 0,
				"epics": 0,
				"mythics": 0,
				"legends": 0,
				"specials": 0,
				"is_free": 0,
				"seconds_to_open": 0,
				"rank_id": 0,
				"pack_id": "",
				"scenery_id": 0,
			});
			store.dispatch('setChosenPackCurrency', '');
			this.add = false;
		},
		async duplicatePacks() {
			store.dispatch('duplicatePackRequest', this.resetUpdate);
		},
		async createPacks() {
			store.dispatch('createPackRequest', this.resetNormal);
		},
		async updatePacks() {
			store.dispatch('updatePackRequest', this.resetUpdate);
		},
		sureDelete() {
			let text = "Se confermi saranno permanentemente eliminati anche i prodotti degli utenti legati a questo pacchetto.";
			if (confirm(text))
				this.deletePacks();
		},
		async deletePacks() {
			store.dispatch('deletePackRequest', this.resetUpdate);
		},
		filterList() {
			store.dispatch('filterPackList');
			this.trier();
		},
	},
	mounted() {
		var main = Math.floor(this.filter_packs.length / this.quantity);
		var resto = this.filter_packs.length % this.quantity;
		this.pages = main + (resto > 0 ? 1 : 0);
	}
}
</script>


<style>
.add-pack-bar {
	display: flex;
	flex-direction: row;
	margin: 10px auto;
	align-items: center;
	justify-content: space-between;
}

.filter-bar {
	height: fit-content;
	background-color: var(--secondary-color);
	border-radius: 15px;
	color: white;
}

.search-tools {
	display: flex;
	flex-direction: column;
	background-color: var(--secondary-color);
	margin: 20px;
	padding: 10px;
	border-radius: 20px;
	border: 2px solid black;
	right: 0;
}

.pages-index {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
}

.pages-index span {
	font-family: 'Fredoka';
	font-weight: 600;
	color: #faebd7;
	cursor: pointer;
	min-width: 24px;
}


.search-row {
	position: relative;
	display: flex;
	flex-direction: row;
	margin: 10px;
	justify-content: space-around;
	align-items: center;
}

.search-row .v-input {
	max-width: 90px;
}

.search-row .v-input__control {
	font-family: 'Fredoka';
	font-weight: 600;
	max-height: 48px;
}

.search-row p {
	padding: 0;
	margin: 0;
	font-family: 'Fredoka';
	font-weight: 600;
	color: antiquewhite;
}

.cat-search-row {
	position: relative;
	display: flex;
	flex-direction: row;
	margin: 10px;
	justify-content: space-around;
	align-items: center;
}

.cat-search-row .v-input__control {
	font-family: 'Fredoka';
	font-weight: 600;
	max-height: 48px;
}

.cat-search-row p {
	padding: 0;
	margin: 0;
	font-family: 'Fredoka';
	font-weight: 600;
	color: antiquewhite;
}


.quest-column {
	border-radius: 20px;
	box-shadow: 0 5px 15px rgb(86, 193, 5);
	margin-top: 20px;
	margin-left: 10px;
	margin-right: 10px;
	margin-bottom: 20px;
	padding: 10px;
}


.quest-icon {
	display: flex;
	flex-direction: column;
	margin: 10px;
	min-width: 60px;
}

.new-quest-icon {
	display: flex;
	flex-direction: column;
	max-width: 250px;
}
.title-icon {
	display: flex;
	flex-direction: row;
	gap: 20px;
	margin: 1rem;
}

.title-icon h1 {
	width: fit-content;
}


.table-header {
	background-color: antiquewhite;
	box-shadow: 0 3px 5px black;
}

.table-header th {
	padding: 2px;
}

.header-row {
	display: flex;
	flex-direction: row;
}

.div-tile {
	display: flex;
	flex-direction: row;
	margin: 2px;
}

.table-tile {
	background-color: cadetblue;
}

.new-quests-btn {
	width: 150px;
	height: 50px;
	background-color: antiquewhite;
	border-radius: 20px;
	border: 2px solid black;
}
</style>