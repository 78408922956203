<template>
    <div class="category-bar">
        <v-tabs v-model="tab" background-color="var(--secondary-color)" centered dark icons-and-text>

            <v-tab v-for="(category, index) in questCategories" :key="index" @click="handleClick(category.category_id)">
                {{ category.name }}
            </v-tab>

        </v-tabs>
    </div>
</template>
<script>
import store from '@/store/index.js'
export default {
    name: "QuestCategoryBar",
    data() {
        return {
            tab: null,
        }
    },
    methods: {
        handleClick(id) {
            store.dispatch('setChosenListCategory', id);
        },
    },
    computed: {
        questCategories() {
            var types = [
                {
                    category_id: null,
                    name: 'Tutto'
                }
            ];

            store.getters.filter_questCategories.forEach(element => {
                if (parseInt(element.event_id) === store.getters.updateEvent.id) {
                    types.push({
                        category_id: element.id,
                        name: store.getters.questCategoryTypes[element.type]
                    });
                }
            });
            return types;
        }
    }
}
</script>
<style>
.category-bar {
    position: relative;
    display: flex;
    flex-direction: row;
    margin: 15px auto;
    width: fit-content;
}
</style>