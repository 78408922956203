<template>
    <v-card class="flex column quest-card" :style="{ backgroundColor: item.active ? '#b4f36d' : '#f77070' }"
        @click="handleClick">
        <v-card-title><strong>{{ item.pack_id }}</strong></v-card-title>
        <v-card-text class="flex row pack-card-body">
            <div class="flex column pack-icon-loop">
                <div class="currency-row">
                    <strong>ID:</strong> {{ item.id ?? '0' }}
                </div>
                <v-icon class="special-icon" large>
                    {{ packCurrencyIcons[item.is_free] }}
                </v-icon>
            </div>
            <div class="flex column">
                <span>
                    <strong>ID scenario:</strong> {{ item.scenery_id }}
                </span>
                <span>
                    <strong>ID rank:</strong> {{ item.rank_id }}
                </span>
                <span>
                    <strong>Uccelli totali:</strong> {{ item.total_elements }}
                </span>
                <span>
                    <strong>Monete minimo:</strong> {{ item.min_coins }}
                </span>
                <span>
                    <strong>Monete massimo:</strong> {{ item.max_coins }}
                </span>
                <span>
                    <strong>Piume minimo:</strong> {{ item.min_feathers }}
                </span>
                <span>
                    <strong>Piume massimo:</strong> {{ item.max_feathers }}
                </span>
            </div>
            <div class="flex column">
                <span>
                    <strong>Comuni:</strong> {{ item.commons }}
                </span>
                <span>
                    <strong>Rare:</strong> {{ item.rares }}
                </span>
                <span>
                    <strong>Epiche:</strong> {{ item.epics }}
                </span>
                <span>
                    <strong>Mitiche:</strong> {{ item.mythics }}
                </span>
                <span>
                    <strong>Leggendarie:</strong> {{ item.legends }}
                </span>
                <span>
                    <strong>Speciali:</strong> {{ item.specials }}
                </span>
                <span>
                    <strong>Valore:</strong> {{ item.value }}
                </span>
            </div>
        </v-card-text>
    </v-card>
</template>
<script>

import store from '@/store/index.js'
export default {
    name: "PackTile",
    data() {
        return {
        }
    },
    props: { item: Object },
    methods: {
        handleClick() {
            this.$emit('pack-clicked');
        },
    },
    computed: {
        packCurrency() {
            var types = [];
            store.getters.packCurrency.forEach(element => {
                types.push(element.name);
            });
            return types;
        },
        packCurrencyIcons() {
            var icons = [];
            store.getters.packCurrency.forEach(element => {
                icons.push(element.icon);
            });
            return icons;
        }
    },
}
</script>