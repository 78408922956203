<template>
    <div class="create-reward-box">
        <span> <strong>ID: </strong></span>
        <input v-model="id" />
        <span> <strong>Punti: </strong></span>
        <input v-model="points" />
        <v-btn id="add-reward-btn" @click="handleClick">Aggiungi</v-btn>
    </div>
</template>
<script>
import store from '@/store';

export default {
    name: "CreateEventRewardsList",
    data() {
        return {
            id: 0,
            points: 0,
            tmp: [],
        }
    },
    methods: {
        handleClick() {
            this.$emit('add-reward-clicked', this.id, this.points);
        },
    },
    computed: {
        eventRewards() {
            return store.getters.eventRewards;
        }
    },
}
</script>
<style>
.create-reward-box {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 5px 3px;
    padding: 5px;
    text-align: flex-start;
    border: 1px solid black;
    border-radius: 7px;
    background-color: rgb(54, 171, 54);
}

#add-reward-btn {
    width: min-content;
    margin: 10px auto;
    background-color: rgb(65, 163, 65);
    border: 1px solid whitesmoke;
    color: whitesmoke;
}
</style>