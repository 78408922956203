<template>
    <div class="event-tile" :style="{ backgroundColor: item.active ? '#b4f36d' : '#f77070', borderColor: '#222222' }"
        @click="handleClick">
        <div class="event-tile-column">
            <v-icon class="special-icon">
                {{ typesIcons[typesName[item.type]] }}
            </v-icon>
            <span>{{ item.unlock_level }}</span>
        </div>
        <div class="flex row space-between margin20 gap30 center-row">
            <div class="event-tile-column">
                <div class="event-row v-margin10">
                    <strong>Rewards:</strong>
                    <EventRewardsList v-if="item.rewards" :list="item.rewards.length > 1 ? item.rewards : ''"/>
                </div>
                <div class="event-row v-margin10">
                    <strong>Offerte:</strong>
                    <span>
                        {{ item.prod_id }}
                    </span>
                </div>
            </div>
            <div class="event-tile-column">
                <div class="event-row v-margin10">
                    <strong>Inizio:</strong>
                    <span>
                        {{ item.start_at }}
                    </span>
                </div>
                <div class="event-row v-margin10">
                    <strong>Fine:</strong>
                    <span>
                        {{ item.expire_at }}
                    </span>
                </div>
                <div class="event-row v-margin10">
                    <strong>Disponibilità in secondi:</strong>
                    <span>
                        {{ item.available_for }}
                    </span>
                </div>
                <div class="event-row v-margin10">
                    <strong>ID row:</strong>
                    <span>
                        {{ item.row_id }}
                    </span>
                </div>
                <div class="event-row v-margin10">
                    <strong>Difficolta:</strong>
                    <span>
                        {{ item.difficulty ?? '0' }}
                    </span>
                </div>
                <div class="event-row v-margin10">
                    <strong>ID evento:</strong>
                    <span>
                        {{ item.id ?? '0' }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import store from '@/store/index.js'
import EventRewardsList from './EventRewardsList.vue';
export default {
    name: "EventTile",
    data() {
        return {
        }
    },
    props: { item: Object },
    components: { EventRewardsList },
    methods: {
        handleClick() {
            this.$emit('event-clicked', this.item);
        },
    },
    computed: {
        typesName() {
            return store.getters.eventTypesName;
        },
        typesIcons() {
            return store.getters.eventTypesIcons;
        },
    },
}
</script>
<style>
.event-tile {
    margin: 5px;
    border: solid 3px;
    align-items: center;
    display: flex;
    flex-direction: row;
}

.event-tile-column {
    display: flex;
    flex-direction: column;
    margin: 0 20px;
}

</style>