<template>
    <v-card class="quest-category-card" :style="{ backgroundColor: currentQuestCategory.active ? '#85eb0f' : '#f77070' }">
        <v-card-text class="flex row">
            <div class="flex column gap30">
                <div class="flex column">
                    <div class="flex row">
                        <strong>Tipo: </strong>
                        <v-select :value="chosenQuestCategoryType" solo :items="questCategoryTypes" v-on:change="setType">
                        </v-select>
                    </div>
                    <div class="flex row">
                        <strong>ID evento:</strong> <v-spacer></v-spacer> <input v-model="currentQuestCategory.event_id" />
                    </div>
                    <div class="flex column">
                    <input type="checkbox" class="checkbox" v-model="currentQuestCategory.active" />
                    <strong style="margin: auto;">{{ currentQuestCategory.active ? 'Attivo' : 'Disattivo' }}</strong>
                </div>
                </div>
            </div>
            <div class="flex column">
                <div class="flex row">
                    <strong>Categoria abbinata:</strong>
                    <v-spacer></v-spacer>
                    <input v-model="currentQuestCategory.related_category" />
                </div>
                <div class="flex row">
                    <strong>Secondi per sblocco:</strong>
                    <v-spacer></v-spacer>
                    <input v-model="currentQuestCategory.seconds_to_unlock" />
                </div>
                <div class="flex row">
                    <strong>Secondi per blocco:</strong>
                    <v-spacer></v-spacer>
                    <input v-model="currentQuestCategory.seconds_to_lock" />
                </div>
                <div class="flex row">
                    <strong>Tipo sblocco:</strong> <v-spacer></v-spacer> <input
                        v-model="currentQuestCategory.unlock_type" />
                </div>
                <div class="flex row">
                    <strong>Configurazione sblocco:</strong> <v-spacer></v-spacer> <input
                        v-model="currentQuestCategory.unlock_type_config" />
                </div>
            </div>
        </v-card-text>
    </v-card>
</template>
<script>

import store from '@/store/index.js'
export default {
    name: "QuestCategoryCategoryCard",
    data() {
        return {
        }
    },
    props: {
        new: Boolean
    },
    methods: {
        setType(value) {
            if (this.new)
                store.dispatch('setChosenQuestCategoryType', value);
            else
                store.dispatch('setUpdateChosenQuestCategoryType', value);
        },
    },
    computed: {
        currentQuestCategory() {
            return this.new ? store.getters.questCategory : store.getters.updateQuestCategory;
        },
        chosenQuestCategoryType() {
            return this.new ? store.getters.chosenQuestCategoryType : store.getters.updateChosenQuestCategoryType;
        },
        questCategoryTypes() {
            return store.getters.questCategoryTypes;
        }
    }
}
</script>
<style>
.quest-category-card {
    margin: 5px;
    border: solid 3px;
    align-items: center;
    justify-content: space-around;
}

.quest-category-card .row {
    margin: 10px;
    align-items: center;
}

.quest-category-card strong {
    margin-right: 13px;
}

.v-text-field__details {
    display: none;
}
</style>