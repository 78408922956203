import Vue from 'vue';
import axios from 'axios';
import store from '@/store';

// Set base URL
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL || '';

// Request interceptor
axios.interceptors.request.use(
  function(config) {
    // Add Authorization header
    config.headers.Authorization = store.getters.authHeader;
    config.headers.Accept = "application/json, text/plain, text/csv";
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

// Response interceptor
axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    // Handle 5xx errors
    if (error.response && error.response.status >= 500) {
      store.dispatch('contentAppDialog', "Errore, segnala l'evento agli sviluppatori.");
      store.dispatch('toggleAppDialog');
    }
    // Handle 401 Unauthorized
    if (error.response && error.response.status === 401) {
      store.dispatch('contentAppDialog', "Accesso non autorizzato, possibili motivi: Token scaduto");
      store.dispatch('toggleAppDialog');
      store.dispatch('logOut');
      store.dispatch('setLogged', false);
    }
    return Promise.reject(error);
  }
);

// Install the plugin
const Plugin = {
  install(Vue) {
    Vue.prototype.$axios = axios;
    Vue.$axios = axios;
  },
};

Vue.use(Plugin);

export default Plugin;
