import axios from 'axios';

const itemModule = {
  state: {
    items: [],
    sorted_items: [],
    item_types: [],
    selected_item: '',
    selected_index: 0
  },
  getters: {
    items(state) {
      return state.items;
    },
    sorted_items(state) {
      return state.sorted_items;
    },
    item_types(state) {
      return state.item_types;
    },
    selectedItem(state) {
      return state.selected_item;
    },
    selectedIndex(state) {
      return state.selected_index;
    }
  },
  mutations: {
    setItems(state, payload) {
      let lista = [];
      state.item_types = [];
      payload.forEach(element => {
        if (!lista[element.item]) {
          lista[element.item] = [];
          state.item_types.push(element.item);
        }
        lista[element.item].push(element)
      });
      state.items = payload;
      state.sorted_items = lista;
    },
    setSortedItems(state, payload) {
      const { item, list, callback } = payload;

      const newList = state.sorted_items;

      newList[item] = list;

      state.sorted_items = newList;

      callback();
    },
    setSelectedItem(state, payload) {
      state.selected_item = payload;
    },
    setSelectedIndex(state, payload) {
      state.selected_index = payload;
    },
  },
  actions: {
    setItem({ commit }, payload) {
      commit('setItem', payload);
    },
    setSortedItems({ commit }, payload) {
      commit('setSortedItems', payload);
    },
    setSelectedItem({ commit }, payload) {
      const { item, index } = payload;
      commit('setSelectedItem', item);
      commit('setSelectedIndex', index);
    },
    async getItemRequest({ commit }) {
      axios
        .get('api/items/get')
        .then((res) => {
          if (res.data.status) {
            commit("setItems", res.data.data);
          } else {
            commit("logOut");
            commit('setLogged', false);
          }
        })
        .catch(() => {
          commit("logOut");
          commit('setLogged', false);
        });
    },
    async updateItemRequest({ commit }, e) {
      const data = { value: e.value };
      await axios
        .put('api/items/update/' + e.id, data)
        .then(() => {
          commit('setResponse', true);
          commit('setSnackbarText', "Modificato item ID: " + e.id);
          commit('setSnackbar', true);
        }).catch((error) => {
          if (error.response != null) {
            commit('setResponse', false);
            commit('setSnackbarText', error.response.data.data ?? error);
            commit('setSnackbar', true);
          }
        });
    },
    async createItemRequest({ commit, state }, callback) {
      const e = state.item;
      e.range = state.itemTypesIndex[state.chosenItemType];
      state.itemRewards.forEach((element, index) => {
        state.item.rewards += element.id + "," + element.points + (index < state.itemRewards.length - 1 ? "|" : "");
      });
      await axios
        .post('api/items/create/', e)
        .then((res) => {
          if (res.data.status) {
            commit("setItems", res.data.data);
            commit('setResponse', true);
            commit('setSnackbarText', "Creato nuova itemo");
            commit('setSnackbar', true);
          } else {
            commit('setResponse', false);
            commit('setSnackbarText', res.data.data);
            commit('setSnackbar', true);
          }
          callback();
        }).catch((error) => {
          commit('setResponse', false);
          commit('setSnackbarText', error.response.data.data ?? error);
          commit('setSnackbar', true);
          callback();
        });
    },
    async deleteItemRequest({ commit, state }, callback) {
      const e = state.updateItem;
      await axios
        .delete('api/items/delete/' + e.id)
        .then((res) => {
          if (res.data.status) {
            commit("setItems", res.data.data);
            commit('setResponse', true);
            commit('setSnackbarText', "Eliminato itemo ID: " + e.id);
            commit('setSnackbar', true);
          } else {
            commit('setResponse', false);
            commit('setSnackbarText', res.data.data);
            commit('setSnackbar', true);
          }
          callback();
        }).catch((error) => {
          commit('setResponse', false);
          commit('setSnackbarText', error.response.data.data ?? error);
          commit('setSnackbar', true);
          callback();
        });
    }
  },
};

export default itemModule;
