<template>
	<div class="quest-body">
		<section class="quest-column">
			<header class="title-icon">
				<v-icon large>mdi-gift</v-icon>
				<h1>Premi</h1>
			</header>
			<div class="flex row align-items gap20 v-margin10">
				<div class="search-row">
					<p>
						<strong>Items:</strong>
					</p>
					<div style="width: 10px;"></div>
					<v-select v-model="quantity" solo :items="quantita" v-on:input="trier()" />
					<div style="width: 10px;"></div>
					<p>/ {{ filter_rewards.length }}</p>
				</div>
				<div class="flex row align-items space-between filter-bar">
					<div class="flex fit-content">
						<span v-on:click="proa('created_at')">Creazione</span>
						<v-icon>{{ order_creat ? 'mdi-menu-up' : 'mdi-menu-down' }}</v-icon>
					</div>
					<div class="flex fit-content">
						<span v-on:click="proa('updated_at')">Modifica</span>
						<v-icon>{{ order_update ? 'mdi-menu-up' : 'mdi-menu-down' }}</v-icon>
					</div>
					<div class="flex fit-content">
						<span v-on:click="proa('id')">ID</span>
						<v-icon>{{ order_id ? 'mdi-menu-up' : 'mdi-menu-down' }}</v-icon>
					</div>
				</div>
				<v-icon large v-on:click="toggleSearch">
					mdi-search-web
				</v-icon>
			</div>
			<div class="pages-index">
				<span v-for="num in pages" :key="num" v-on:click="changePage(num)"
					:style="{ color: page === num ? '#b4f36d' : '#faebd7' }">{{ num }}</span>
			</div>
			<RewardTile
				v-for="( reward, index ) in filter_rewards.slice(((current_page - 1) * quantity), (current_page * quantity))"
				:key="index" :item="reward" @reward-clicked="selectReward(index + ((current_page - 1) * quantity))">
			</RewardTile>
		</section>
		<div>
			<section class="quest-column">
				<header>
					<div class="add-quest-bar">
						<h1>Aggiungi premio</h1>
						<v-icon size="47" class="l-margin20 r-margin10" color="green" v-on:click="add = !add">{{ add ?
							'mdi-eye-off-outline' : 'mdi-eye-outline' }}</v-icon>
					</div>
				</header>
				<div v-if="add">
					<RewardCard :new="true" />
					<button class="new-quests-btn" v-on:click="askID">CONFERMA</button>
				</div>
			</section>
			<section id="update_quest" class="quest-column">
				<header>
					<div class="add-quest-bar">
						<h1>Modifica o elimina premio</h1>
						<v-icon size="47" class="l-margin20 r-margin10" color="green"
							v-on:click="isUpdateSet ? upd = !upd : null">{{ upd ?
								'mdi-eye-off-outline' : 'mdi-eye-outline' }}</v-icon>
					</div>
				</header>
				<div v-if="upd">
					<RewardCard :new="false" />
					<button class="new-quests-btn" v-on:click="undo()">ANNULLA</button>
					<button class="new-quests-btn" v-on:click="updateRewards()">MODIFICA</button>
					<button class="new-quests-btn" v-on:click="deleteRewards()">ELIMINA</button>
				</div>
			</section>
			<div v-if="search" class="search-tools">
				<div class="search-row">
					<p>
						ID:
					</p>
					<v-spacer></v-spacer>
					<input type="text" name="input-id" v-model="id_filter">
				</div>
				<div class="search-row">
					<p>
						Pacchetti:
					</p>
					<v-spacer></v-spacer>
					<input type="text" name="input-packs" v-model="pack_id_filter">
				</div>
				<div class="cat-search-row">
					<p>
						Tipo:
					</p>
					<v-spacer></v-spacer>
					<v-select v-model="type_filter" solo :items="rewardTypesSelect" v-on:change="findType()" />
				</div>
			</div>
		</div>
		<v-dialog transition="dialog-top-transition" max-width="600" v-model="care">
			<v-card>
				<v-toolbar color="primary" dark>Prestare attenzione</v-toolbar>
				<div class="text-h3 pa-12">CONFLITTO ID</div>
				<v-card-text>
					{{ dialogText }}
				</v-card-text>
				<v-card-actions class="justify-end">
					<v-btn text @click="createRewards">Conferma</v-btn>
					<v-btn text @click="care = !care">Chiudi</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>


<script>
import store from '@/store/index.js'
import RewardCard from '@/views/Reward/RewardCard.vue';
import RewardTile from '@/views/Reward/RewardTile.vue';
import axios from 'axios';

export default {
	name: 'EventRewards',
	data: () => ({
		search: false,
		id_filter: '',
		pack_id_filter: '',
		type_filter: '',
		cat_search: 9999,
		quantita: [15, 30, 50, 100],
		quantity: 15,
		page: 1,
		pages: 0,
		order_creat: false,
		order_update: false,
		order_id: false,
		order_event_id: false,
		add: false,
		upd: false,
		care: false,
		dialogText: ''
	}),
	components: { RewardCard, RewardTile },
	computed: {
		isUpdateSet() {
			return store.getters.updateReward.id !== undefined;
		},
		token() {
			return store.getters.token;
		},
		rewards() {
			return store.getters.rewards;
		},
		filter_rewards() {
			var types = [];
			this.eventRewards.forEach(element => {
				for (let index = 0; index < store.getters.filter_rewards.length; index++) {
					const reward = store.getters.filter_rewards[index];
					if (parseInt(element) === reward.id) {
						types.push(reward);
						break;
					}
				}
			});
			return types;
		},
		current_page() {
			return this.page;
		},
		rewardTypesSelect() {
			var types = ['Nessuno'];
			store.getters.rewardTypes.forEach(element => {
				types.push(element.name);
			});
			return types;
		},
		rewardTypes() {
			var types = [];
			store.getters.rewardTypes.forEach(element => {
				types.push(element.name);
			});
			return types;
		},
		eventRewards() {
			var types = [];
			store.getters.updateEvent.rewards.split('|').forEach(element => {
				types.push(element.split(',')[0]);
			});
			return types;
		},
	},
	methods: {
		async askID() {
			if (store.getters.reward.id == null || store.getters.reward.id <= 0) {
				this.dialogText = "Stai creando un elemento senza ID. Il server la creera con un ID sequenziale che potrebbe portare a conflitti con la PROD. Procedi SOLO, se sai cosa stai facendo.";
				this.care = true;
				return;
			}
			axios
				.get('https://2fgamestudio.com/api/taken/rewards?id=' + store.getters.reward.id)
				//.get('api/taken/rewards?id=' + store.getters.reward.id)
				.then((res) => {
					if (res.data.status) {
						if (!res.data.data)
							this.createRewards();
						else {
							this.dialogText = "L'ID che stai inserendo esiste gia in PROD. Procedi SOLO, se sai cosa stai facendo.";
							this.care = true;
						}
					} else {
						this.care = true;
					}
				})
				.catch(() => {
					store.dispatch('setResponse', false);
					store.dispatch('setSnackbarText', "Errore");
					store.dispatch('setSnackbar', true);
				});
		},
		proa(column) {
			var flag = false;
			if (column === 'created_at') {
				this.order_creat = !this.order_creat
				flag = this.order_creat;
				this.filter_rewards.sort(function (a, b) {
					if (flag)
						return new Date(a[column]) > new Date(b[column]);
					else
						return new Date(a[column]) < new Date(b[column]);
				});
			}
			if (column === 'updated_at') {
				this.order_update = !this.order_update
				flag = this.order_update;
				this.filter_rewards.sort(function (a, b) {
					if (flag)
						return new Date(a[column]) > new Date(b[column]);
					else
						return new Date(a[column]) < new Date(b[column]);
				});
			}
			if (column === 'id') {
				this.order_id = !this.order_id
				flag = this.order_id;
				this.filter_rewards.sort(function (a, b) {
					if (flag)
						return a[column] > b[column];
					else
						return a[column] < b[column];
				});
			}
		},
		changePage(num) {
			this.page = num;
		},
		findType() {
			if (this.type_filter === 'Nessuno')
				this.cat_search = 9999;
			else
				this.cat_search = this.rewardTypes.indexOf(this.type_filter);

			this.filterList();
		},
		trier() {
			var main = Math.floor(this.filter_rewards.length / this.quantity);
			var resto = this.filter_rewards.length % this.quantity;
			this.pages = main + (resto > 0 ? 1 : 0);
			this.page = 1;
		},
		toggleSearch() {
			this.search = !this.search;
			if (!this.search)
				return;

			setTimeout(() => {
				document.getElementsByName("input-id")[0].addEventListener('input', this.filterList);
				document.getElementsByName("input-packs")[0].addEventListener('input', this.filterList);
			}, 100);
		},
		selectReward(index) {
			store.dispatch('setUpdateReward', this.filter_rewards[index]);
			if (!this.upd)
				this.upd = true;
		},
		undo() {
			this.resetUpdate();
			this.upd = false;
		},
		resetUpdate() {
			store.dispatch('setUpdateReward', {});
			store.dispatch('setUpdateChosenRewardType', '');
			this.upd = false;
		},
		resetNormal() {
			store.dispatch('setReward', {});
			store.dispatch('setChosenRewardType', '');
			this.add = false;
		},
		async createRewards() {
			this.care = false;
			store.dispatch('createRewardRequest', this.resetNormal);
		},
		async updateRewards() {
			store.dispatch('updateRewardRequest', this.resetUpdate);
		},
		async deleteRewards() {
			store.dispatch('deleteRewardRequest', this.resetUpdate);
		},
		filterList() {
			store.dispatch('setFilterRewards', this.rewards.filter(reward =>
			(
				reward.id.toString().includes(this.id_filter) &&
				(reward.pack_id ?? '').includes(this.pack_id_filter) &&
				(this.cat_search === 9999 ? true : reward.type === this.cat_search)
			)
			));
			this.trier();
		},
	},
	mounted() {
		var main = Math.floor(this.filter_rewards.length / this.quantity);
		var resto = this.filter_rewards.length % this.quantity;
		this.pages = main + (resto > 0 ? 1 : 0);
	}
}
</script>