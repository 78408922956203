const statsModule = {
  state: {
    deltaBird: [],
    minsmeBird: [],
    everBird: [],
    krugerBird: [],
    hawaiiBird: [],
    manuBird: [],
  },
  getters: {
    deltaBirds(state){
      return state.deltaBird;
    },
    minsmeBirds(state){
      return state.minsmeBird;
    },
    everBirds(state){
      return state.everBird;
    },
    krugerBirds(state){
      return state.krugerBird;
    },
    hawaiiBirds(state){
      return state.hawaiiBird;
    },
    manuBirds(state){
      return state.manuBird;
    },
  },
  mutations: {
    setBirds(state, payload) {
      const { scenery, birds } = payload;
      switch (scenery) {
        case 10001:
          state.deltaBird = birds;
          break;
        case 10002:
          state.minsmeBird = birds;
          break;
        case 10003:
          state.everBird = birds;
          break;
        case 10004:
          state.krugerBird = birds;
          break;
        case 10005:
          state.hawaiiBird = birds;
          break;
        case 10006:
          state.manuBird = birds;
          break;
        default:
          break;
      }
    }
  },
  actions: {
    setBirds({ commit }, payload) {
      commit('setBirds', payload);
    }
  }
};

export default statsModule;
