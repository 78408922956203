import store from "./store";

export function deleteFromProduct(id) {
  store.dispatch("setProducts", store.getters.products.filter(element => {
    return element.id !== id;
  }));
}

export function deleteFromReward(id) {
  store.dispatch("setRewards", store.getters.rewards.filter(element => {
    return element.id !== id;
  }));
}

export function deleteFromQuest(id) {
  store.commit("setQuests", store.getters.quests.filter(element => {
    return element.id !== id;
  }));
}

export function updateFromQuest(quest) {
  store.commit("setQuests", store.getters.quests.map(element => {
    if (element.id === quest.id) {
      return quest;
    } else {
      return element;
    }
  }));
}

export function createFromQuest(quest) {
  const questsCopy = store.getters.quests.slice();
  questsCopy.unshift(quest);
  store.commit("setQuests", questsCopy);
}