<template>
    <v-card class="flex quest-card"
        :style="{ backgroundColor: !item.active ? '#f77070' : currentQuest.id === item.id ? '#c402a1' : '#fd51dd' }"
        @click="handleClick">
        <v-card-text class="flex row">
            <div class="flex column minW170">
                <v-icon class="special-icon">
                    {{ item.range == 0 ? 'mdi-bird' : item.range == 1 ? 'mdi-map-marker' : 'mdi-earth' }}
                </v-icon>
                <span v-if="item.range == 0 || item.range == 1">{{ item.range_id }}</span>
                <span v-if="item.rarity !== null && item.rarity >= 0" class="fit-content m-auto">Rarità: {{
                    rarityFilter[item.rarity] }}</span>
            </div>
            <div class="flex column">
                <div class="flex row">
                    <strong>Classe:</strong> {{ questClass[item.quest_class] !== undefined ?
                        questClass[item.quest_class].name : '' }}
                </div>
                <div class="flex row">
                    <strong>Tipo:</strong> {{ questType.replace('X', item.number_to_get) }}
                </div>
                <div class="flex row">
                    <strong>Numero:</strong> {{ item.number_to_get }}
                </div>
                <div class="flex row">
                    <strong>Punti:</strong> {{ item.points_to_get }}
                </div>
                <div class="flex row">
                    <strong>Ripetizione missione:</strong> {{ item.available_times }}
                </div>
                <div class="flex row">
                    <strong>Categoria:</strong> {{ typesName }}
                </div>
            </div>
            <div class="flex column">
                <div class="flex row">
                    <strong>ID missione:</strong> {{ item.id }}
                </div>
                <div class="flex row">
                    <strong>ID evento:</strong> {{ item.event_id ?? '0' }}
                </div>
            </div>
        </v-card-text>
    </v-card>
</template>
<script>

import store from '@/store/index.js'
export default {
    name: "QuestTile",
    data() {
        return {
        }
    },
    props: { item: Object },
    methods: {
        handleClick() {
            this.$emit('quest-clicked', this.item);
        },
    },
    computed: {
        currentQuest() {
            return store.getters.updateQuest;
        },
        rangesName() {
            return store.getters.questRangesName;
        },
        typesName() {
            var cont = '';
            store.getters.questCategories.forEach(element => {
                if (element.id === this.item.category_id)
                    cont = store.getters.questCategoryTypes[element.type];
            });

            return cont;
        },
        rarityFilter() {
            return store.getters.questRarityFilter;
        },
        rangesIcons() {
            return store.getters.questRangesIcons;
        },
        rarityName() {
            return store.getters.questRarityName;
        },
        questClass() {
            return store.getters.questClass;
        },
        questType() {
            var cont = '';
            if (store.getters.questClass[this.item.quest_class] !== undefined)
                store.getters.questClass[this.item.quest_class].types.forEach(element => {
                    if (element.type === this.item.quest_type)
                        cont = element.name;
                });
            return cont;
        },
        categories() {
            var cont = '';
            store.getters.questCategories.forEach(element => {
                if (element.id === this.currentQuest.category_id)
                    cont = store.getters.questCategoryTypes[element.type];
            });

            return cont;
        }
    },
}
</script>
<style>
.minW170 {
    min-width: 170px;
}
</style>