<template>
	<div class="item-view-container">
		<ItemList></ItemList>
		<section class="item-edit-box" v-if="openDialog">
			<header>
				<h1>
					{{ item ? item.item.replace('_', ' ').toUpperCase() : '' }}
				</h1>
			</header>
			<span v-if="item.scenery > 0"><strong>Scenario: {{ item.scenery }}</strong></span>
			<span v-if="item.level > 0"><strong>Livello: {{ item.level }}</strong></span>
			<span v-if="item.scenery == 0 && item.level == 0"><strong>Rarita: {{ item.rarity }}</strong></span>
			<div class="item-edit-box-details">
				<v-icon large
				:style="{ color: (item.products ? 'rgb(11, 95, 5)' : 'rgba(137, 1, 1, .5)'), margin: '10px 7px' }">mdi-store</v-icon>
				<v-icon large
				:style="{ color: (item.rewards ? 'rgb(11, 95, 5)' : 'rgba(137, 1, 1, .5)'), margin: '10px 7px' }">mdi-gift</v-icon>
				<v-icon large
				:style="{ color: (item.packs ? 'rgb(11, 95, 5)' : 'rgba(137, 1, 1, .5)'), margin: '10px 7px' }">mdi-cards</v-icon>
			</div>
			<span><strong>Valore per unità: {{ item.value }}  </strong><v-icon large>mdi-hand-coin</v-icon> </span>
			<input style="position: relative; width: fit-content; margin: 5px auto;" type="number" v-model="item.value" :placeholder="item.value">
			<div style="display: flex; justify-content: flex-end;">
				<v-btn style="margin: 10px 10px; background-color: rgb(16, 69, 4); color: bisque;"
					v-on:click="updateValue"><strong>CONFERMA</strong></v-btn>
				<v-btn style="margin: 10px 10px; background-color: rgb(16, 69, 4); color: bisque;"
					v-on:click="closeDialog"><strong>CHIUDI</strong></v-btn>
			</div>
		</section>
	</div>
</template>
<script>
import ItemList from './ItemList.vue';
import store from '@/store';
export default {
	name: "ItemView",
	data() {
		return {
			item_selected: 0,
		}
	},
	components: { ItemList },
	computed: {
		sorted_items() {
			return this.$store.getters.sorted_items;
		},
		item() {
			if (store.getters.selectedItem.length > 0)
				return this.sorted_items[store.getters.selectedItem][store.getters.selectedIndex];
			return false;
		},
		dialogo() {
			return this.item_selected != 0;
		},
		openDialog() {
			return store.getters.selectedItem !== '';
		},
		selectedIndex() {
			return store.getters.selectedIndex;
		},
	},
	methods: {
		updateValue() {
			store.dispatch('updateItemRequest', this.item);
		},
		closeDialog() {
			store.dispatch('setSelectedItem', { item: '', index: 0 });

			var centerTable = document.getElementsByClassName('item-view-container');
			centerTable[0].style.justifyContent = 'space-around';
			// document.querySelectorAll('.center-table tbody td').forEach(td => {
			// 	td.style.width = '120px';
			// });
		}
	},
}
</script>
<style>
.item-view-container {
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	margin-top: 20px;
}

.item-edit-box {
	position: fixed;
	width: 512px;
	top: 50%;
	right: 0;
	height: fit-content;
	display: flex;
	flex-direction: column;
	background-color: var(--secondary-color);
	border-radius: 10px;
	color: bisque;
}

.item-edit-box-details {
	position: relative;
	display: flex;
	flex-direction: row;
	width: fit-content;
	margin: auto;
}
</style>