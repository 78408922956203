<template>
	<div class="flex row">
		<section class="FW_rarity-column">
			<header class="title-icon">
				<v-icon large >mdi-format-list-numbered</v-icon>
				<h1>Rarita Ruota fortuna</h1>
			</header>
			<v-card v-for="( FW_rarity, index ) in rarities" :key="index" class="flex column FW-rarity-card"
				:style="{ backgroundColor: '#b4f36d', borderColor: '#222222' }" @click="vai(index)">
				<v-card-title>{{ FW_rarity.name }}</v-card-title>
				<v-card-text class="flex column">
					<span>
						<strong>Rarita:</strong> {{
				rarities_index(FW_rarity.name) }}
					</span>
					<span>
						<strong>Limite ricompense:</strong> {{ FW_rarity.limit }}
					</span>
					<span>
						<strong>Percentuale:</strong> {{ FW_rarity.percentage }}
					</span>
				</v-card-text>
			</v-card>
		</section>
		<section v-if="rarity" class="FW_rarity-column">
			<header>
				<h1>Modifica o elimina rarita ruota fortuna</h1>
			</header>
			<v-card class="new-product-card" :style="{ backgroundColor: '#b4f36d', borderColor: '#222222' }">
				<v-card-text>
					<div class="flex row">

						<div class="flex column">
							<div class="flex row maxH30 minH30">
								<strong>Nome:</strong> <input v-model="update_FW_rarity.name" />
							</div>
							<div class="flex row maxH30 minH30">
								<strong>Rarita:</strong> <span> {{
				rarities_index(update_FW_rarity.name) }}</span>
							</div>
						</div>
						<div class="flex column">
							<div class="flex row maxH30 minH30">
								<strong>Limite ricompese:</strong> {{ update_FW_rarity.limit }}
							</div>
							<div class="flex row maxH30 minH30">
								<strong>Percentuale rarita:</strong> <input v-model="update_FW_rarity.percentage" />
							</div>
						</div>

					</div>
				</v-card-text>
			</v-card>
			<div class="row v-margin10 space-around">
				<button class="new-event-btn" v-on:click="undo()">ANNULLA</button>
				<button class="new-event-btn" v-on:click="updateRarity()">MODIFICA</button>
			</div>
		</section>
	</div>
</template>


<script>
import axios from 'axios';
import store from '@/store/index.js'
import { getFW, getCheckpoints } from '@/functions'


export default {
	name: 'FWRarityView',
	data: () => ({
		FW_rarity: {
			"rarity": 0,
			"name": 0,
			"limit": 0,
			"percentage": 0,
		},
		rarity: false,
		rarity_name: '',
		update_FW_rarity: {},
		search: false,
		snackbar: false,
		snackbar_text: '',
		quantita: [15, 30, 50, 100],
		quantity: 15,
		page: 1,
		pages: 0,
		response: false,
	}),
	computed: {
		token() {
			return store.getters.token;
		},
		rarities_name() {
			var ch = [];
			for (var i in store.getters.rarities) {
				ch.push(store.getters.rarities[i]['name']);
			}
			return ch;
		},
		rarities() {
			return store.getters.rarities;
		},
		current_page() {
			return this.page;
		}
	},
	methods: {
		rarities_index(name) {
			for (var i in store.getters.rarities) {
				if (store.getters.rarities[i]['name'] === name)
					return i;
			}
			return null;
		},
		vai(index) {
			this.update_FW_rarity = Object.assign({}, this.rarities[index]);
			this.rarity_name = this.update_FW_rarity.name;
			this.rarity = true;
		},
		undo() {
			this.update_FW_rarity = {};
			this.rarity = false;
		},
		async updateRarity() {
			await axios
				.put('api/fortune/wheel/rarity/update/' + this.rarities_index(this.rarity_name), this.update_FW_rarity)
				.then((res) => {
					if (res.data.status) {
						store.dispatch("setRarities", res.data.data);
						this.response = true;
						this.snackbar_text = "Modificata Rarita: " + this.rarities_index(this.update_FW_rarity.name);
						this.snackbar = true;
						setTimeout(() => {
							this.snackbar = false;
						}, 2500);
					} else {
						this.response = false;
						this.snackbar_text = res.data.data;
						this.snackbar = true;
					}
				}).catch((error) => {
					this.response = false;
					this.snackbar_text = error.response.data.data;
					this.snackbar = true;
				});
		},
	},
	async mounted() {
		getFW();
		getCheckpoints();
	}
}
</script>


<style>
.filter-bar {
	height: fit-content;
	padding: 10px;
	background-color: var(--secondary-color);
	border-radius: 15px;
	color: white;
}

.side-legend {
	display: flex;
	flex-direction: column;
	position: fixed;
	right: 0;
	width: 300px;
	border-radius: 17px;
	margin: 5px;
	padding: 10px;
	background-color: #b2ff9f;
}

.search-tools {
	position: absolute;
	display: flex;
	flex-direction: column;
	background-color: var(--secondary-color);
	margin: 20px;
	padding: 10px;
	border-radius: 20px;
	border: 2px solid black;
	right: 0;
}

.pages-index {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
}

.pages-index span {
	font-family: 'Fredoka';
	font-weight: 600;
	color: #faebd7;
	cursor: pointer;
	min-width: 24px;
}


.search-row {
	position: relative;
	display: flex;
	flex-direction: row;
	margin: 10px;
	justify-content: space-around;
	align-items: center;
}

.search-row .v-input {
	max-width: 90px;
}

.search-row .v-input__control {
	font-family: 'Fredoka';
	font-weight: 600;
	max-height: 48px;
}

.search-row p {
	padding: 0;
	margin: 0;
	font-family: 'Fredoka';
	font-weight: 600;
	color: antiquewhite;
}

.cat-search-row {
	position: relative;
	display: flex;
	flex-direction: row;
	margin: 10px;
	justify-content: space-around;
	align-items: center;
}

.cat-search-row .v-input__control {
	font-family: 'Fredoka';
	font-weight: 600;
	max-height: 48px;
}

.cat-search-row p {
	padding: 0;
	margin: 0;
	font-family: 'Fredoka';
	font-weight: 600;
	color: antiquewhite;
}


.FW_rarity-body {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-top: 110px;
	gap: 10px;
	overflow-x: scroll;
}

.FW_rarity-column {
	border-radius: 20px;
	width: fit-content;
	height: fit-content;
	box-shadow: 0 5px 15px rgb(86, 193, 5);
	margin-top: 20px;
	margin-left: 10px;
	margin-right: 10px;
	margin-bottom: 20px;
	padding: 10px;
}

.FW_rarity-body h1 {
	font-family: 'Cairo';
	font-weight: 900;
	color: #ffe4c4;
	text-align: flex-start;
}

.FW_rarity-icon {
	display: flex;
	flex-direction: column;
	margin: 10px;
	min-width: 60px;
}

.new-FW_rarity-icon {
	display: flex;
	flex-direction: column;
	max-width: 250px;
}

.FW-rarity-card {
	margin: 5px;
	border: solid 3px;
	align-items: center;
}

.FW-rarity-card .row {
	margin: 10px;
}

.FW-rarity-card strong {
	margin-right: 13px;
}

.FW-rarity-card input {
	max-width: 65px;
	min-width: 50px;
}

.FW_rarity-body input {
	min-height: 30px;
	margin: 0 auto;
}

.title-icon {
	display: flex;
	flex-direction: row;
	gap: 20px;
	margin: 1rem;
}

.title-icon h1 {
	width: fit-content;
}


.table-header {
	background-color: antiquewhite;
	box-shadow: 0 3px 5px black;
}

.table-header th {
	padding: 2px;
}

.header-row {
	display: flex;
	flex-direction: row;
}

.div-tile {
	display: flex;
	flex-direction: row;
	margin: 2px;
}

.table-tile {
	background-color: cadetblue;
}

.new-event-btn {
	width: 150px;
	height: 50px;
	background-color: antiquewhite;
	border-radius: 20px;
	border: 2px solid black;
}
</style>