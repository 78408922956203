import axios from 'axios';

const questCategoryModule = {
  state: {
    questCategories: [],
    filter_questCategories: [],
    questCategory: {
      "id": 0,
      "active": 0,
      "type": 0,
      "unlock_type": 0,
      "unlock_type_config": 0,
      "related_category": 0,
      "seconds_to_unlock": 0,
      "seconds_to_lock": 0,
      "event_id": 0
    },
    questCategoryTypes: [
      "Day 1",
      "Day 2",
      "Day 3",
      "Day 4",
      "Day 5",
      "Day 6",
      "Day 7",
      "Birds",
      "Stars",
      "Duels",
      "Cameras",
      "Licenses",
      "Consumables",
      "Day 1-2",
      "Day 2-3",
      "Day 3-4",
      "Day 4-5",
      "Day 5-6",
      "Day 6-7",
      "Phase 1",
      "Phase 2",
      "Phase 3",
      "Phase 4",
      "Phase 5",
      "Phase 6",
      "Phase 7",
      "Final",
      "Gear",
      "Po Delta",
      "Minsmere",
      "Everglades",
      "Kruger",
      "Hawaii",
      "Manu",
      "Papua New Guinea",
      "Commons",
      "Rares",
      "Epics",
      "Mythics",
      "Legendaries",
      "Specials",
      "Extra",
    ],
    updateQuestCategory: {},
    chosenQuestCategoryType: '',
    updateChosenQuestCategoryType: '',
  },
  getters: {
    questCategories(state) {
      return state.questCategories;
    },
    filter_questCategories(state) {
      return state.filter_questCategories;
    },
    questCategory(state) {
      return state.questCategory;
    },
    updateQuestCategory(state) {
      return state.updateQuestCategory;
    },
    questCategoryTypes(state) {
      return state.questCategoryTypes;
    },
    chosenQuestCategoryType(state) {
      return state.chosenQuestCategoryType;
    },
    updateChosenQuestCategoryType(state) {
      return state.updateChosenQuestCategoryType;
    }
  },
  mutations: {
    setQuestCategories(state, payload) {
      state.questCategories = payload;
      state.filter_questCategories = payload;
    },
    setFilterQuestCategories(state, payload) {
      state.filter_questCategories = payload;
    },
    setQuestCategory(state, payload) {
      state.questCategory = payload;
    },
    setUpdateQuestCategory(state, payload) {
      state.updateQuestCategory = payload;
      state.updateChosenQuestCategoryType = state.questCategoryTypes[payload.type];
    },
    setChosenQuestCategoryType(state, payload) {
      state.chosenQuestCategoryType = payload;
    },
    setUpdateChosenQuestCategoryType(state, payload) {
      state.updateChosenQuestCategoryType = payload;
    },
  },
  actions: {
    setFilterQuestCategories({ commit }, payload) {
      commit('setFilterQuestCategories', payload);
    },
    setQuestCategory({ commit }, payload) {
      commit('setQuestCategory', payload);
    },
    setUpdateQuestCategory({ commit }, payload) {
      commit('setUpdateQuestCategory', payload);
    },
    setChosenQuestCategoryType({ commit }, payload) {
      commit('setChosenQuestCategoryType', payload);
    },
    setUpdateChosenQuestCategoryType({ commit }, payload) {
      commit('setUpdateChosenQuestCategoryType', payload);
    },
    async getQuestCategoryRequest({ commit }) {
      axios
        .get('api/quests/category/get')
        .then((res) => {
          if (res.data.status) {
            commit("setQuestCategories", res.data.data);
            commit('setFilterQuestCategories', res.data.data);
            commit('setQuestQuestCategories', res.data.data);
          } else {
            commit("logOut");
            commit('setLogged', false);
          }
        })
        .catch(() => {
          commit("logOut");
          commit('setLogged', false);
        });
    },
    async cloneQuestCategoryRequest({ commit, state }, payload) {
      const { callback } = payload;
      await axios
        .post('api/quests/category/clone/' + state.updateQuestCategory.id)
        .then((res) => {
          if (res.data.status) {
            commit("setQuestCategories", res.data.data.categorie);
            commit('setFilterQuestCategories', res.data.data.categorie);
            commit('setQuestQuestCategories', res.data.data.categorie);
            commit("setQuests", res.data.data.quest);
            commit('setResponse', true);
            commit('setSnackbarText', "Clonata categoria ID: " + state.updateQuestCategory.id);
            commit('setSnackbar', true);
            callback();
          } else {
            commit('setResponse', false);
            commit('setSnackbarText', res.data.data);
            commit('setSnackbar', true);
          }
        }).catch((error) => {
          commit('setResponse', false);
          commit('setSnackbarText', error.response.data.data ?? error);
          commit('setSnackbar', true);
        });
    },
    async updateQuestCategoryRequest({ commit, state }, callback) {
      const q = state.updateQuestCategory;
      q.type = state.questCategoryTypes.indexOf(state.updateChosenQuestCategoryType);
      await axios
        .put('api/quests/category/update/' + q.id, q)
        .then((res) => {
          if (res.data.status) {
            commit("setQuestCategories", res.data.data);
            commit('setFilterQuestCategories', res.data.data);
            commit('setQuestQuestCategories', res.data.data);
            commit('setResponse', true);
            commit('setSnackbarText', "Modificata categoria ID: " + q.id);
            commit('setSnackbar', true);
          } else {
            commit('setResponse', false);
            commit('setSnackbarText', res.data.data);
            commit('setSnackbar', true);
          }
          callback();
        }).catch((error) => {
          commit('setResponse', false);
          commit('setSnackbarText', error.response.data.data ?? error);
          commit('setSnackbar', true);
          callback();
        });
    },
    async createQuestCategoryRequest({ commit, state }, callback) {
      if (state.questCategory.event_id == null || state.questCategory.event_id <= 0) {
        commit('setResponse', false);
        commit('setSnackbarText', "La categoria non ha nessun evento.");
        commit('setSnackbar', true);
        return;
      }
      
      const q = state.questCategory;
      q.type = state.questCategoryTypes.indexOf(state.chosenQuestCategoryType);

      await axios
        .post('api/quests/category/create/', q)
        .then((res) => {
          if (res.data.status) {
            commit("setQuestCategories", res.data.data);
            commit('setFilterQuestCategories', res.data.data);
            commit('setQuestQuestCategories', res.data.data);
            commit('setResponse', true);
            commit('setSnackbarText', "Creata nuova categoria");
            commit('setSnackbar', true);
          } else {
            commit('setResponse', false);
            commit('setSnackbarText', res.data.data);
            commit('setSnackbar', true);
          }
          callback();
        }).catch((error) => {
          commit('setResponse', false);
          commit('setSnackbarText', error.response.data.data ?? error);
          commit('setSnackbar', true);
          callback();
        });
    },
    async deleteQuestCategoryRequest({ commit, state }, callback) {
      const q = state.updateQuestCategory;
      await axios
        .delete('api/quests/category/delete/' + q.id)
        .then((res) => {
          if (res.data.status) {
            commit("setQuestCategories", res.data.data);
            commit('setFilterQuestCategories', res.data.data);
            commit('setQuestQuestCategories', res.data.data);
            commit('setResponse', true);
            commit('setSnackbarText', "Eliminate categoria ID: " + q.id);
            commit('setSnackbar', true);
          } else {
            commit('setResponse', false);
            commit('setSnackbarText', res.data.data);
            commit('setSnackbar', true);
          }
          callback();
        }).catch((error) => {
          commit('setResponse', false);
          commit('setSnackbarText', error.response.data.data ?? error);
          commit('setSnackbar', true);
          callback();
        });
    }
  },
};

export default questCategoryModule;
